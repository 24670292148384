<template>
  <div v-if="path!=='reg'">
    <div v-if="path!=='enterpriseIndex'">
      <div v-if="path!=='bigNumber'">
      <div v-if="path!=='xzjobLogin'">
      <div class="header" v-if="path!=='answerExam'">
        <div class="headerFlex" >
          <div class="headerTabFlex">
            <div>
              <img src="images/eLogoSvg.png" alt="" class="headerLogo">
    <!--          <p style="font-size: 12px">育工匠 融校企 促就业 创未来</p>-->
            </div>
            <ul class="headerLi" v-if="path!=='courseVideo'">
              <li v-for="(item,index) in tabTitle" :key="index" @click="changeHeader(index)" :class="haderShow==index?'changeheaderLi':'headerLili'">{{item.name}}</li>
            </ul>
            <div v-if="path=='courseVideo'" style="width: 380px">
              <el-input placeholder="请输入内容" v-model="courseSeach" class="input-with-select" @change="CourseList">
                <el-button slot="append" icon="el-icon-search" @click="CourseList()"></el-button>
              </el-input>
            </div>
          </div>
          <div class="headerTabFlex">
    <!--        <div class="quickEntryBg" v-if="userInfo&&userInfo.teacherType">-->
    <!--          <p class="quickEntry">快捷入口</p>-->
    <!--          <div class="quickEntryBgDiv">-->
    <!--            <p v-for="(item,index) in quickEntry" :key="index" @click="goInstructionCenter(index)">{{item.name}}</p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="quickEntryBg" v-if="userInfo&&!userInfo.teacherType">-->
    <!--          <p class="quickEntry">快捷入口</p>-->
    <!--          <div class="quickEntryBgDiv">-->
    <!--            <p v-for="(item,index) in StdquickEntry" :key="index" @click="goUserCenter(index)">{{item.name}}</p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <el-input @change="goSearchList" v-model="searchInput" placeholder="请输入内容" class="headerInput" >-->
    <!--          <el-button slot="append" icon="el-icon-search" @click="goSearchList"></el-button>-->
    <!--        </el-input>-->
<!--            <span>个人VIP</span>-->
            <img src="images/courseListIcon.png" style="width: 90px;cursor: pointer" @click="goCourseList"/>
            <span class="vipBuy" @click="goAffrimOrder">个人VIP</span>
            <img class="avartarImg" v-if="userInfo&&userInfo.teacherType" :src="userInfo.logo?userInfo.logo:'images/tacitlyAvatar.png'">
            <img class="avartarImg" v-if="userInfo&&!userInfo.teacherType" :src="userInfo.headPortraitUrl?userInfo.headPortraitUrl:'images/tacitlyAvatar.png'">
            <div v-if="userName||telNumber||userInfo" class="userPOsition">
              <p class="userNames" v-if="userName||telNumber">{{userName?userName:telNumber}}</p>
              <p class="userNames" v-if="!userName&&!telNumber">{{userInfo.name?userInfo.name:userInfo.telNumber}}</p>
              <div class="userPOsitiondiv">
    <!--            <p v-for="(item,index) in account" :key="index" class="accountHover" @click="changeAccount(index)">{{item.name}}</p>-->
                <div style="border-bottom: 1px solid #DDDDDD;padding: 16px;">
                  <div class="zanwuFlex">
                    <img v-if="userInfo&&userInfo.teacherType" :src="userInfo.logo?userInfo.logo:'images/tacitlyAvatar.png'">
                    <img v-if="userInfo&&!userInfo.teacherType" :src="userInfo.headPortraitUrl?userInfo.headPortraitUrl:'images/tacitlyAvatar.png'">
                    <div>
                      <p >{{userInfo.name?userInfo.name:telNumber}}</p>
                      <p v-if="userInfo&&userInfo.teacherType&&userInfo.account">账号: {{userInfo.account}}</p>
                      <p v-if="userInfo&&!userInfo.teacherType&&userInfo.telNumber">账号: {{userInfo.telNumber}}</p>
                    </div>
                  </div>
                  <div v-if="userInfo&&!userInfo.teacherType&&vipEndTime" class="VipTime" >
                    <p>VIP于{{vipEndTime}}到期</p>
                    <el-button round size="small" @click="goAffrimOrder">续费</el-button>
                  </div>
                </div>

                <div class="shortcutArrFlex" v-if="userInfo&&userInfo.teacherType">
                  <div v-for="(item,index) in shortcutArr" :key="index" @click="goInstructionCenter(index)">
                    <img :src="item.img" />
                    <p>{{item.name}}</p>
                  </div>
                </div>
                <div class="shortcutArrFlex" v-if="userInfo&&!userInfo.teacherType">
                  <div v-for="(item,index) in stdShortcutArr" :key="index" @click="goUserCenter(index)">
                    <img :src="item.img" />
                    <p>{{item.name}}</p>
                  </div>
                </div>
                <div class="administrationFlex">
                  <div v-if="userInfo&&userInfo.teacherType" v-for="(item,index) in teacherAdministration" :key="index" @click="goInstructionCenter(item.adindex)">
                    <img :src="item.img" />
                    <span style="cursor: pointer">{{item.name}}</span>
                  </div>
                  <div v-if="userInfo&&!userInfo.teacherType" v-for="(item,index) in stdAdministration" :key="index" @click="goUserCenter(item.adindex)">
                    <img :src="item.img" />
                    <span style="cursor: pointer">{{item.name}}</span>
                  </div>
                  <div v-if="userInfo&&userInfo.teacherType">
                    <div>
                      <img src="images/icon6.png" />
                      <span style="cursor: pointer" @click="goInstructionCenter(6)">账号设置</span>
                    </div>
                    <span style="font-size: 14px;cursor: pointer;font-weight: bold" @click="changeAccount()">退出</span>
                  </div>
                  <div v-if="userInfo&&!userInfo.teacherType">
                    <div>
                      <img src="images/stdIcon7.png" />
                      <span style="cursor: pointer" @click="goUserCenter(6)">个人中心</span>
                    </div>
                    <span style="font-size: 14px;cursor: pointer;font-weight: bold" @click="changeAccount()">退出</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="headerTabFlex" v-else>
              <router-link to="reg" class="headerCenter">登录</router-link>
              <p>|</p>
              <router-link :to="{path:'/reg',query:{reg:'reg'}}" class="headerCenter">注册</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  </div>
</template>

<script>
import {addVisit} from '@/api'
export default {
  data(){
    return{
      searchInput:'',
      tabTitle:[
        {id:1,name:"首页"},
        {id:2,name:"授课中心"},
        {id:3,name:"实战训练"},
        {id:4,name:"职教高考"},
        // {id:5,name:"创新创业"},
        {id:6,name:"职场提升"},
        // {id:7,name:"职业测评"},
        // {id:8,name:"小智Job"},
        {id:9,name:"大数据"},
        {id:10,name:'小智Job'}
      ],
      haderShow:0,
      path:"",
      userName:'',
      telNumber:'',
      account:[
        {id:1,name:'账号管理'},
        {id:2,name:'退出登录'},
      ],
      quickEntry:[
        {id:1,name:'授课管理'},
        {id:2,name:'阅卷管理'},
        {id:3,name:'作业管理'},
        {id:4,name:'学生管理'},
      ],
      StdquickEntry:[
        {id:1,name:'我的E小智'},
        {id:2,name:'我的课程'},
        {id:3,name:'我的收藏'},
        {id:4,name:'学习笔记'},
      ],
      userInfo:{},
      shortcutArr:[
        {id:1,name:'授课管理',img:'images/shouke.png'},
        {id:2,name:'阅卷管理',img:'images/ceshi.png'},
        {id:3,name:'作业管理',img:'images/zuoye.png'},
        {id:4,name:'学生管理',img:'images/student.png'},
      ],
      stdShortcutArr:[
        {id:1,name:'我的课程',img:'images/studentIcon1.png'},
        {id:2,name:'我的收藏',img:'images/studentIcon2.png'},
        {id:3,name:'购物车',img:'images/studentIcon3.png'},
        {id:4,name:'我的订单',img:'images/studentIcon4.png'},
      ],
      teacherAdministration:[
        {id:1,name:'班级管理',img:'images/icon2.png',adindex:'4'},
        {id:2,name:'课程管理',img:'images/icon4.png',adindex:'5'},
      ],
      stdAdministration:[
        {id:1,name:'我的班级',img:'images/stdIcon6.png',adindex:'4'},
        {id:2,name:'APP下载',img:'images/studentIcon5.svg',adindex:'5'},
      ],
      courseSeach:'',
      vipEndTime:''
    }
  },
  methods:{

    changeHeader(index){
      if(index==3||index==4){
        let data={
          type:''
        }
        if(index==4){
          data.type='ZCTS'
        }
        if(index==3){
          data.type='ZJGK'
        }
        addVisit(data).then(res=>{

        })
      }

      let userInfo=JSON.parse(localStorage.getItem("userInfo")) ;
      this.haderShow=index;
      if(index==0){
        this.$router.push('/');
        this.$other.$emit("indexHeaderShow", 0);
      }
      if(index==1){
        if(userInfo){
          if(userInfo.teacherType){
            this.$router.push({ path: "/classCenter" });
          }else{
            this.$router.replace('/userCenterIndex')
          }
        }else if(!userInfo){
          this.$message.error('当前状态未登录,请登录!');
          this.$router.push({path: '/reg', query: {r: 'true'}})
        }
        this.$other.$emit("indexHeaderShow", 1);
      }
      if(index==2){
        if(userInfo){
          this.$router.push({ path: "/raiseBody" });
        }else if(!userInfo){
          this.$message.error('当前状态未登录,请登录!');
          this.$router.push({path: '/reg', query: {actual: 'true'}})
        }
        this.$other.$emit("indexHeaderShow", 2);
      }
      if(index==3){
        // if(userInfo){
          this.$router.push({ path: "/vocationalExeam" });}
        // }else if(!userInfo){
        //   this.$message.error('当前状态未登录,请登录!');
        //   this.$router.push({path: '/reg', query: {college: 'true'}})
        // }
        this.$other.$emit("indexHeaderShow", 3);
      // }
      if(index==4){
        this.$router.push({ path: "/postCourse" });
        this.$other.$emit("indexHeaderShow", 4);
      }
      if(index==5){
        this.$router.push({ path: "/numberIndex" });
        this.$other.$emit("indexHeaderShow", 5);
      }
      if(index==6){
        this.$router.push({ path: "/xzJob" });
        this.$other.$emit("indexHeaderShow", 6);
      };
    },
    getPath(){
      this.path=this.$route.name

      this.userInfo=JSON.parse(localStorage.getItem("userInfo")) ;
    },
  //  账号设置
    changeAccount(index){
        this.$cookie.remove("userToken");
        this.$cookie.remove("userName");
        this.$cookie.remove("studyFrontToken");
        sessionStorage.clear();
        localStorage.clear();
        this.$router.push("reg");
        this.haderShow='0'
    },
  //  去搜索列表页面
    goSearchList(){
      this.$router.push({path:'courseList',query:{searchName:this.searchInput}});
      this.searchInput=''
    },
  //  去授课中心
    goInstructionCenter(index){
      this.$other.$emit("indexHeaderShow", 1);
      if(index==0){
        sessionStorage.setItem('quickEntryShow',2)
      }else if(index==1){
        sessionStorage.setItem('quickEntryShow',3)
      }else if(index==2){
        sessionStorage.setItem('quickEntryShow',4)
      }else if(index==3){
        sessionStorage.setItem('quickEntryShow',5)
      }else if(index==4){
        sessionStorage.setItem('quickEntryShow',6)
      }else if(index==5){
        sessionStorage.setItem('quickEntryShow',7)
      }else if(index==6){
        sessionStorage.setItem('quickEntryShow',8)
      }
      let routeUrl = this.$router.resolve({
        path: "/classCenter",
      });
      window.open(routeUrl.href);

    },
    //去上课中心
    goUserCenter(index){
      this.$other.$emit("indexHeaderShow", 1);
      if(index==5){
        this.haderShow =6
      }else{
        this.haderShow =1
      }
      if(index==0){
        sessionStorage.setItem('stdQuickEntryShow',3)
      }else if(index==1){
        sessionStorage.setItem('stdQuickEntryShow',4)
      }else if(index==2){
        // sessionStorage.setItem('stdQuickEntryShow',5)
      }else if(index==3){
        sessionStorage.setItem('stdQuickEntryShow',6)
      }else if(index==4){
        sessionStorage.setItem('stdQuickEntryShow',7)
      }else if(index==5){
        this.$router.push({path: "/shiftApp"})
      }else if(index==6){
        sessionStorage.setItem('stdQuickEntryShow',9)
      }
      if(index==5){
        let routeUrl = this.$router.resolve({
          path: "/shiftApp",
        });
        window.open(routeUrl.href);
      }else if(index==2){
        let routeUrl = this.$router.resolve({
          path: "/courseShopping",
        });
        window.open(routeUrl.href);
      }else{
        let routeUrl = this.$router.resolve({
          path: "/userCenterIndex",
        });
        window.open(routeUrl.href);
      }

    },
    changeHeaderTitle(){
      let newuserInfo=JSON.parse(localStorage.getItem('userInfo'))
      if(newuserInfo&&!newuserInfo.teacherType){
        this.tabTitle[1]={id:2,name:"学习中心"}
      }
    },
    //  去课程列表页面
    goCourseList() {
      let routeUrl = this.$router.resolve({
        path: "/courseList",
        query:{
          data:'all'
        }
      });
      window.open(routeUrl.href);
    },
    //去课程列表
    CourseList(){
      let routeUrl = this.$router.resolve({
        path: "/courseList",
        query:{
          courseQuery:this.courseSeach,
          data:'all'
        }
      });
      window.open(routeUrl.href);
    },
  //  去订单确认页面
    goAffrimOrder(){
      let routeUrl = this.$router.resolve({
        path: "/courseOrder",
        query:{type:'allTotal',allPrice:'allPrice',orderShow:'0'}
      });
      window.open(routeUrl.href);
    }
  },
  created(){
    this.userInfo=JSON.parse(localStorage.getItem("userInfo")) ;
    if(this.userInfo&&this.userInfo.vipEndTime&&this.userInfo.vipEndTime!==null){
      this.vipEndTime=this.userInfo.vipEndTime.slice(0,10)
    }
    this.$other.$on('setIndexNav',(res)=>{
      this.haderShow = res
      this.$other.$emit("indexHeaderShow", res);
    })
    this.userName = this.$cookie.get("userName");
    // if(this.$store.state.telNumber){
    //   this.telNumber = this.$store.state.telNumber
    // }
    // if(this.$store.state.userName){
    //   this.userName = this.$store.state.userName;
    // };
    this.$other.$on("indexHeaderShow", (index)=>{
      if(index === 1||index === 2||index === 3){
        this.changeHeaderTitle();
      }
    })
    this.changeHeaderTitle();
  },
  computed: {
    storeNickName(){
      this.userName = this.$store.state.userName;
    },
    storeUserPhone(){
      this.telNumber = this.$store.state.telNumber;
    },
    localPhone(){
      let newUserinfo=JSON.parse(localStorage.getItem("userInfo")) ;
      if(newUserinfo&&newUserinfo.name){
        this.userName = newUserinfo.name;
      }else if(newUserinfo&&newUserinfo.telNumber){
        this.telNumber = newUserinfo.telNumber;
      }else if(newUserinfo&&!newUserinfo.telNumber&&!newUserinfo.name&&newUserinfo.teacherType){
        this.telNumber = newUserinfo.account;
      }
    }
  },
  watch:{
    "$route":"getPath",
    // 不要删
    storeUserPhone:{
      handler(item){
      },
      immediate: true,
      deep: true
    },
    // 不要删
    storeNickName:{
      handler(item){

      },
      immediate: true,
      deep: true
    },
    localPhone:{
      handler(item){
      },
      immediate: true,
      deep: true
    },
  },
}
</script>

<style scoped>
  .header{
    width: 100%;
    margin: 0 auto;
    position: fixed;
    z-index: 999;
    background: white;
    padding: 20px 20px 4px 20px;
  }
  .headerFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 61px;
  }
  .headerTabFlex{
    display: flex;
    align-items: center;
    /*padding-bottom: 10px;*/
    padding-right: 20px;
  }
  .headerLogo{
    width: 134px;
    margin-right: 70px;
    /*padding-bottom: 10px;*/
  }
  .headerLi{
    display: flex;
    width: 512px;
    justify-content: space-between;
  }
  .headerCenter{
    width: 58px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    color: #333333;
  }
  .headerCenter:hover{
    color: #49B151;
  }
  .headerLili{
    cursor: pointer;
    color: #333333;
    padding-bottom: 10px;
  }
  .headerLili:hover{
    color: #409EFF;
  }
  .headerInput{
    height: 34px;
    padding-bottom: 10px;
  }
  .changeheaderLi{
    color: #409EFF;
    cursor: pointer;
    font-weight: bold;
    border-bottom: 2px solid #409EFF;
  }
  .userNames{
    /*width: 150px;*/
    text-align: left;
    cursor: pointer;
    height: 48px;
    line-height: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    margin-right: 25px;
  }
  .avartarImg{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 20px;
  }
  .userPOsition{
    position: relative;
  }
  .userPOsitiondiv{
    position: absolute;
    top: 49px;
    right: 10px;
    background: white;
    z-index: 9;
    width: 360px;
    /*height: 314px;*/
    text-align: center;
    color: #333333;
    /*cursor: pointer;*/
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 10px 0;
    display: none;
  }
  .userNames:hover {
    color: #49B151;
  }
  .userPOsition:hover  .userPOsitiondiv{
    display: block;
  }
  .accountHover{
    height: 48px;
    line-height: 48px;
  }
  .accountHover:hover{
    color: #49B151;
  }
  .quickEntry:hover{
    color: #49B151;
  }
  .quickEntry{
    width: 152px;
    cursor: pointer;
    text-align: center;
    line-height: 38px;
    height: 45px;
  }
  .quickEntryBg{
    position: relative;
  }

  .quickEntryBg>div{
    position: absolute;
    top: 40px;
    left: 4px;
    background: white;
    z-index: 9;
    width: 145px;
    text-align: center;
    color: #333333;
    cursor: pointer;
    box-shadow: 0px 2px 6px #4f4f4f;
    padding: 10px 0;
    display: none;
  }
  .quickEntryBg:hover .quickEntryBgDiv{
    display: block;
  }
  .quickEntryBgDiv{
    transition: all 0.5s;
  }
  .quickEntryBg>div>p{
    padding: 6px 0;
  }
  .quickEntryBg>div>p:hover{
    color: #4AB152;
  }
  .zanwuFlex{
    display: flex;
    text-align: left;

  }
  .zanwuFlex>div>p:first-child{
    font-weight: bold;
    margin-bottom: 8px;
  }
  .zanwuFlex>div>p:last-child{
    color: #999999;
    font-size: 14px;
  }
  .zanwuFlex>img{
    width: 46px;
    height: 46px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 50%;
  }
  .shortcutArrFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #DDDDDD;
    padding: 10px 0;
  }
  .shortcutArrFlex img{
    width: 26px;
  }
  .shortcutArrFlex>div:not(:last-child){
    border-right: 1px solid #E8E8E8;
  }
  .shortcutArrFlex>div{
    padding: 4px 16px;
    font-size: 14px;
    cursor: pointer;
  }
  .administrationFlex>div{
    display: flex;
    padding:15px;
    font-size: 14px;
  }
  .administrationFlex>div:first-child,.administrationFlex>div:nth-child(2){
    border-bottom: 1px solid #DDDDDD;
  }
  .administrationFlex>div:last-child{
    justify-content: space-between;
  }
  .administrationFlex>div:last-child>div{
    display: flex;
    align-items: center;
  }
  .administrationFlex img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .vipBuy{
    margin: 0 10px;
    background-image: linear-gradient(to right, #f83641 , #f3727b);
    padding: 2px 10px;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    margin-top: 4px;
  }
  .VipTime{
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 6px;
    padding-left: 10px;
  }
  .VipTime>p{
    margin-right: 40px;
  }
</style>
