
/**
 * 生成qq登录链接
 */
const getQQLoginUrl = function (param){
    let url = 'https://graph.qq.com/oauth2.0/authorize?'
    param = Object.assign({
        response_type: 'token',
        client_id: '101968322',
        redirect_uri: 'http%3A%2F%2Fexiaozhi.sdzjds.com%2F%23%3F',
        state: 'code',
        scope: 'get_user_info',
    }, param);
    for(let key in param){
        url += `${key}=${param[key]}&`
    }
    url = url.trim('&');
    return url;

}

const responseIdFormat = function(str){
    var reg = /: *\d{14,20} */g;
    str = str.replace(reg, function(a) {
        return a.replace(/: */g, ":\"").replace(" ", "") + "\"";
    });
    str = str.replace(/\\"id\\":\"*\d{14,20} *\"/g,function(a){
        var idText = a;
        var idNum = idText.substring(idText.indexOf(":")+2,idText.length-1);
        a = a.substring(0,idText.indexOf(":")+1)+"\\\""+idNum+"\\\"";
        return a
    })
    return str;
};
const formatDate=date=> {
    var d = new Date(date);
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate() < 10 ? "0" + d.getDate() : "" + d.getDate();
    var hour = d.getHours();
    var minutes = d.getMinutes();
    var seconds = d.getSeconds();
    return year + "-" + month + "-" + day + " ";
};
module.exports = {
    responseIdFormat:responseIdFormat,
    formatDate:formatDate,
    getQQLoginUrl:getQQLoginUrl,
}
