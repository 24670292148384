<template>
  <div style="background: #474443;width: 100%;height: 180px">
    <div class="companyFlex">
      <div>
        <div class="companyAbout">
          <span @click="goAbout">关于我们</span>
          <span @click="goAbout">联系我们</span>
          <span @click="goRaiseBody">产品方案</span>
          <span @click="goServe">服务中心</span>
          <span @click="goServe">升级下载</span>
          <span @click="goBigNumber">移动端</span>
        </div>
        <div style="margin-bottom: 14px;color: #999999" >
          <span>Copyright 2021 e小智 山东智捷电子商务有限公司版权所有 </span>
          <span style="cursor: pointer;margin-left: 12px" @click="goExternal">鲁ICP备16015943号-2</span>
          <span style="color: white;margin-left: 20px;cursor: pointer" @click="goExternal">工业和信息化部备案管理系统网站</span>
        </div>
        <div style="margin-bottom: 14px;color: #999999">
          <span style="margin-right: 12px">全国客服热线：0531-88259780 </span>
          <span> 联系地址：山东省济南市历城区荣盛时代国际C座725</span>
        </div>
      </div>
      <div>
        <p style="margin-bottom: 12px">关注微信公众号</p>
        <img src="images/exiaozhiCode.png"/>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data(){
      return{
        aboutIndexArr:[
          {id:1,name:'公司介绍'},
          {id:2,name:'荣誉资质'},
          {id:3,name:'加入我们'},
          {id:4,name:'联系我们'},
        ],
      }
    },
    methods:{
      //  底部链接
      goExternal(){
        window.open('https://beian.miit.gov.cn/')
      },
      //  去关于我们
      goAbout(){
        this.$router.push('about')
      },
      //  去实战训练
      goRaiseBody(){
        this.$router.push('raiseBody')
      },
      //  去移动端
      goServe(){
        this.$router.push('shiftApp')
      },
      //移动端
      goBigNumber(){
        this.$router.push({ path: "/shiftApp" });
      }
    }
  }
</script>
<style>
.companyFlex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  height: 180px;
  color: white;
  font-size: 14px;
}
.companyAbout>span{
  display: inline-block;
  margin-right: 32px;
  cursor: pointer;
  margin-bottom: 14px;

}
</style>
