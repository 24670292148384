import axios from 'axios'
import Cookie from 'js-cookie';
import {Message,MessageBox} from 'element-ui';
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_PRE_API, // url = base url + request url
    withCredentials: true, // 跨域时发送cookie
    // timeout: 8000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        let userToken= Cookie.get('userToken')
        let headers = {}
        if(userToken) headers['studyFrontToken'] = userToken;
        config.headers = Object.assign(config.headers, headers);
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)
let flag = true;
// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data;
        if(res.code === 100124 && flag){
            flag = false
            MessageBox.alert('您尚未登录或登录信息已过期', '异常', {
                confirmButtonText: '重新登录',
                showClose: false,
                type: 'warning'
            }).then(() => {
                Cookie.remove("userToken");
                Cookie.remove("userName");
                Cookie.remove("studyFrontToken");
                sessionStorage.clear();
                localStorage.clear();
                flag = true
                location.href = '#/reg?r=true';
            })
        }
        return res
    },
    error => {
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
