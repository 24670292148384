import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/ceshi',
    name: 'ceshi',
    component: () => import(/* webpackChunkName: "ceshi" */ '../views/ceshi.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "test" */ '../views/test.vue')
  },
  {
    path:'/wxlogin',
    name: 'wxlogin',
    component: () => import(/* webpackChunkName: "wxlogin" */ '../views/wxlogin.vue')
  },
  {
    path: '/reg',
    name: 'reg',
    component: () => import(/* webpackChunkName: "reg" */ '../views/reg.vue')
  },
  {
    path: '/xzjobLogin',
    name: 'xzjobLogin',
    component: () => import(/* webpackChunkName: "reg" */ '../views/xzjobLogin.vue')
  },
  {
    path: '/raiseBody',
    name: 'raiseBody',
    component: () => import(/* webpackChunkName: "raiseBody" */ '../views/raiseBody.vue')
  },
  {
    path: '/classCenter',
    name: 'classCenter',
    component: () => import(/* webpackChunkName: "classCenter" */ '../views/classCenter/index.vue')
  },
  {
    path: '/addCourse',
    name: 'addCourse',
    component: () => import(/* webpackChunkName: "addCourse" */ '../views/addCourse.vue')
  },
  {
    path: '/courseDetail',
    name: 'courseDetail',
    component: () => import(/* webpackChunkName: "courseDetail" */ '../views/course/courseDetail.vue')
  },
  {
    path: '/courseVideoDetais',
    name: 'courseVideoDetais',
    component: () => import(/* webpackChunkName: "courseDetail" */ '../views/course/courseVideoDetais.vue')
  },
  {
    path: '/speakCourse',
    name: 'speakCourse',
    component: () => import(/* webpackChunkName: "speakCourse" */ '../views/course/speakCourse.vue')
  },
  {
    path: '/prepareCourseCenter',
    name: 'prepareCourseCenter',
    component: () => import(/* webpackChunkName: "courseDetail" */ '../views/course/prepareCourseCenter.vue')
  },
  {
    path: '/prepareCurriculum',
    name: 'prepareCurriculum',
    component: () => import(/* webpackChunkName: "courseDetail" */ '../views/course/prepareCurriculum.vue')
  },
  {
    path: '/checkPaperList',
    name: 'checkPaperList',
    component: () => import(/* webpackChunkName: "courseDetail" */ '../views/checkPaperList.vue')
  },
  {
    path: '/modifyPaper',
    name: 'modifyPaper',
    component: () => import(/* webpackChunkName: "modifyPaper" */ '../views/modifyPaper.vue')
  },
  {
    path: '/questionsDetail',
    name: 'questionsDetail',
    component: () => import(/* webpackChunkName: "questionsDetail" */ '../views/questionsDetail.vue')
  },
  {
    path: '/courseList',
    name: 'courseList',
    component: () => import(/* webpackChunkName: "courseList" */ '../views/course/courseList.vue')
  },
  {
    path: '/courseShopping',
    name: 'courseShopping',
    component: () => import(/* webpackChunkName: "courseShopping" */ '../views/course/courseShopping.vue')
  },
  {
    path: '/courseAfrrimShopping',
    name: 'courseAfrrimShopping',
    component: () => import(/* webpackChunkName: "courseShopping" */ '../views/course/courseAfrrimShopping.vue')
  },
  {
    path: '/courseOrder',
    name: 'courseOrder',
    component: () => import(/* webpackChunkName: "courseOrder" */ '../views/course/courseOrder.vue')
  },
  {
    path: '/xzJob',
    name: 'xzJob',
    component: () => import(/* webpackChunkName: "xzJob" */ '../views/xzJob/userIndex.vue')
  },
  {
    path: '/enterpriseIndex',
    name: 'enterpriseIndex',
    component: () => import(/* webpackChunkName: "xzJob" */ '../views/xzJob/enterpriseIndex.vue')
  },
  {
    path: '/recommendJobList',
    name: 'recommendJobList',
    component: () => import(/* webpackChunkName: "xzJob" */ '../views/xzJob//user/recommendJobList.vue')
  },
  {
    path: '/companyDetail',
    name: 'companyDetail',
    component: () => import(/* webpackChunkName: "xzJob" */ '../views/xzJob//user/companyDetail.vue')
  },
  {
    path: '/hotEnterpriseList',
    name: 'hotEnterpriseList',
    component: () => import(/* webpackChunkName: "xzJob" */ '../views/xzJob//user/hotEnterpriseList.vue')
  },
  {
    path: '/jobDetail',
    name: 'jobDetail',
    component: () => import(/* webpackChunkName: "xzJob" */ '../views/xzJob//user/jobDetail.vue')
  },
  {
    path: '/inviteInfo',
    name: 'inviteInfo',
    component: () => import(/* webpackChunkName: "xzJob" */ '../views/xzJob//user/inviteInfo.vue')
  },
  {
    path: '/cityNumber',
    name: 'cityNumber',
    component: () => import(/* webpackChunkName: "xzJob" */ '../views/xzJob//user/cityNumber.vue')
  },
  {
    path: '/xzJobNewsDetail',
    name: 'xzJobNewsDetail',
    component: () => import(/* webpackChunkName: "xzJob" */ '../views/xzJob//user/xzJobNewsDetail.vue')
  },
  {
    path: '/xzJobnewsList',
    name: 'xzJobnewsList',
    component: () => import(/* webpackChunkName: "xzJob" */ '../views/xzJob//user/xzJobnewsList.vue')
  },
  {
    path: '/numberIndex',
    name: 'numberIndex',
    component: () => import(/* webpackChunkName: "xzJob" */ '../views/bigNumber/numberIndex.vue')
  },
  {
    path: '/bigNumber',
    name: 'bigNumber',
    component: () => import(/* webpackChunkName: "xzJob" */ '../views/bigNumber/bigNumber.vue')
  },
  {
    path: '/newsList',
    name: 'newsList',
    component: () => import(/* webpackChunkName: "newsList" */ '../views/news/newsList.vue')
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import(/* webpackChunkName: "newsDetail" */ '../views/news/newsDetail.vue')
  },
  {
    path: '/teacherList',
    name: 'teacherList',
    component: () => import(/* webpackChunkName: "teacherList" */ '../views/teacher/teacherList.vue')
  },
  {
    path: '/teacherDetail',
    name: 'teacherDetail',
    component: () => import(/* webpackChunkName: "teacherDetail" */ '../views/teacher/teacherDetail.vue')
  },
  {
    path: '/shiftApp',
    name: 'shiftApp',
    component: () => import(/* webpackChunkName: "teacherDetail" */ '../views/about/shiftApp.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "teacherDetail" */ '../views/about/index.vue')
  },
  {
    path: '/examinationAnalyse',
    name: 'examinationAnalyse',
    component: () => import(/* webpackChunkName: "teacherDetail" */ '../views/examination/examinationAnalyse.vue')
  },
  {
    path: '/addExaminationPaper',
    name: 'addExaminationPaper',
    component: () => import(/* webpackChunkName: "teacherDetail" */ '../views/examination/addExaminationPaper.vue')
  },
  {
    path: '/postCourse',
    name: 'postCourse',
    component: () => import(/* webpackChunkName: "teacherDetail" */ '../views/postCourse/index.vue')
  },
  {
    path: '/postCourseDetail',
    name: 'postCourseDetail',
    component: () => import(/* webpackChunkName: "teacherDetail" */ '../views/postCourse/postCourseDetail.vue')
  },
  {
    path: '/workPublish',
    name: 'workPublish',
    component: () => import(/* webpackChunkName: "workPublish" */ '../views/classCenter/components/workPublish.vue')
  },
  {
    path: '/myOrder',
    name: 'myOrder',
    component: () => import(/* webpackChunkName: "workPublish" */ '../views/userCenter/components/myOrder.vue')
  },
  {
    path: '/vocationalExeam',
    name: 'vocationalExeam',
    component: () => import(/* webpackChunkName: "workPublish" */ '../views/vocationalExeam/index.vue')
  },
  {
    path: '/topicList',
    name: 'topicList',
    component: () => import(/* webpackChunkName: "workPublish" */ '../views/vocationalExeam/topicList.vue')
  },
  {
    path: '/informationDetail',
    name: 'informationDetail',
    component: () => import(/* webpackChunkName: "workPublish" */ '../views/vocationalExeam/informationDetail.vue')
  },
  {
    path: '/messageList',
    name: 'messageList',
    component: () => import(/* webpackChunkName: "workPublish" */ '../views/vocationalExeam/messageList.vue')
  },
  {
    path: '/courseVideo',
    name: 'courseVideo',
    component: () => import(/* webpackChunkName: "courseVideo" */ '../views/vocationalExeam/courseVideo.vue')
  },
  {
    path: '/randomExamination',
    name: 'randomExamination',
    component: () => import(/* webpackChunkName: "randomExamination" */ '../views/vocationalExeam/randomExamination.vue')
  },
  {
    path: '/answerExam',
    name: 'answerExam',
    component: () => import(/* webpackChunkName: "randomExamination" */ '../views/vocationalExeam/answerExam.vue')
  },
  {
    path: '/city',
    name: 'city',
    component: () => import(/* webpackChunkName: "randomExamination" */ '../views/vocationalExeam/city.vue')
  },
  {
    path: '/certificate',
    name: 'certificate',
    component: () => import(/* webpackChunkName: "randomExamination" */ '../views/certificate/certificate.vue')
  },
  {
    path: '/certificateCourse',
    name: 'certificateCourse',
    component: () => import(/* webpackChunkName: "randomExamination" */ '../views/certificate/certificateCourse.vue')
  },
  {
    path: '/courserVideos',
    name: 'courserVideos',
    component: () => import(/* webpackChunkName: "randomExamination" */ '../views/certificate/courserVideos.vue')
  },
  //用户中心首页
  {
    path: '/userCenterIndex',
    name: 'userCenterIndex',
    component: () => import(/* webpackChunkName: "teacherDetail" */ '../views/userCenter/index.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
