
<template>
  <div class="home">
    <!--    banner-->
    <div class="homeBanner">
      <el-carousel :interval="60000" arrow="always">
        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
          <img :src="item.filePath" alt="" style="width: 100%;height: 100%"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--    产品服务-->
    <div>
      <div>
        <div class="serveBgWhite">
          <div class="serveBgMRCenter">
            <div class="productServe">
              <p>解决方案</p>
              <p>TOTAL SOLUTION</p>
            </div>
            <div class="serveClassfyFlex">
              <div v-for="(item,index) in serveClassify" :key="index" :class="serveType==index?'serveClassfyActive':''"
                   class="serveClassfyFlexDiv" @mouseenter="serveHover(index)">
                <img :src="item.img"/>
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="schemeFlexMrCenter">
          <div class="schemeFlex">
            <div class="schemeFlexMrl">
              <transition name="el-zoom-in-center">
                <div v-if="serveType==0" class="schemeFlexImg">
                  <div  class="schemeFlexP">
                    <img  src="images/index.png"/>
                    <div>
                      <p>
                        基于真实应用驱动实训教学的电子商务专业建设整体解决方案
                      </p>
                      <p>个性化量身定制 打造品牌专业</p>
                      <p>
                        基于真实应用驱动实训教学的电子商务专业建设整体解决方案，是在电商行业数字化发展趋势背景下。根据电商全产业链岗位群人才市场需求，结合学校电子商务专业学生实训教学实际情况，培训电商企业文化环境建设等的一体化电商专业建设整体解决方案。</p>
                    </div>
                  </div>
                </div>
              </transition>
              <transition name="el-zoom-in-center">
                <div v-if="serveType==1" class="schemeFlexImg">
                  <div class="schemeFlexP">
                    <img  src="images/homeImg1.png"/>
                    <div>
                      <p>
                        校本、学科、精品资源库建设
                      </p>
                      <p>符合发展需求、建立科学课程体系</p>
                      <p>
                        致力于电子商务专业前瞻性教育理念的研究专业建设。以知名院校学术专家，多年电子商务行业经验大咖、知名企业实战专家为讲师团，在院校的课程体系之上重新梳理课程研发思路，根据行业经验研发出专业核心课程资源，供院校和学生使用。校企联合开发电子教案、教学设计、教学文档、视频微课、教学动画、实战题库等资源库</p>
                    </div>
                  </div>
                </div>
              </transition>
              <transition name="el-zoom-in-center">
                <div v-if="serveType==2" class="schemeFlexImg">
                  <div  class="schemeFlexP">
                    <img  src="images/homeImg2.png"/>
                    <div>
                      <p>
                        配套教学服务体系
                      </p>
                      <p>因材施教 让兴趣成为最好的老师</p>
                      <p>依托行业领先课程体系，构建完善的教学服务体系，从而为培养应用型人才奠定坚实的基础。公司经过长期校企合作的沉淀与积累，简历鲜明特色的教学服务体系，并形成完善的服务考核要求及标准</p>
                    </div>
                  </div>
                </div>
              </transition>
              <transition name="el-zoom-in-center">
                <div v-if="serveType==3" class="schemeFlexImg">
                  <div  class="schemeFlexP">
                    <img  src="images/homeImg3.png"/>
                    <div>
                      <p>
                        真实应用驱动实训教学
                      </p>
                      <p>教学相长 让实践与学习有效融合</p>
                      <p>以就业需求为导向，以提升学生就业竞争力为目的。改革教学思路、开拓眼界，与企业接轨实现就创业“零距离”，将最前沿电商运营理念和技术引入教学过程，实现教学方法的改革</p>
                    </div>
                  </div>
                </div>
              </transition>
              <transition name="el-zoom-in-center">
                <div v-if="serveType==4" class="schemeFlexImg">
                  <div class="schemeFlexP">
                    <img  src="images/homeImg4.png"/>
                    <div>
                      <p>
                        多源数据分析，精准匹配就业
                      </p>
                      <p>毕业即就业 轻松找工作</p>
                      <p>公司旗下专属人力资源平台，根据电商全产业链岗位群人才市场需求，从产业、行业、企业、职业调查入手，提供职业能力大数据分析，绘制用户画像，精准推送至优质企业</p>
                    </div>
                  </div>
                </div>
              </transition>
<!--              <p class="understandMOre">了解更多</p>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    精品体系课-->
    <div class="BoutiqueBg">
      <div class="boutiqueMrs">
        <div>
          <div class="productServe boutiqueCenter">
            <p>精品体系课</p>
            <p>EXCELLENT SYSTEM COURSE</p>
          </div>
        </div>
        <div class="bouteiqueMore">
          <p class="more" @click="goCourseList('Boutique')">更多</p>
          <span>>></span>
        </div>
        <div class="BoutiqueLessonFlex">
          <div class="boutiqueCasourImgFlex">
            <div class="boutiqueCasourImg" v-for="(item,index) in courserListArr.slice(0,6)" :key="index" @click="goCourseDetail(item)">
              <img class="boutiqueImgs"  :src="item.logo"/>
              <div class="boutiqueCasourImgPad">
                <p class="bouteiqueCaourseName">{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div @click="changeOpenVideo" class="indexVideoImg">
            <img :src="filePath" class="indexIMgFile">
            <img src="images/playVideo.png">
          </div>
<!--          <div style="width: 865px">-->
<!--            <vue-aliplayer-v2 ref="VueAliplayerV2" :options="videoOptions" />-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <!--    最新资讯-->
    <div class="zixunFlex zixunmr">
      <div class="zixuncenter">
        <div class="productServe boutiqueCenter">
          <p>最新资讯</p>
          <p>THE lATEST INFORMATION </p>
        </div>
      </div>
      <div class="bouteiqueMore">
        <p class="more" @click="gonewsList()">更多</p>
        <span>>></span>
      </div>
      <div class="zixuncontentFlex">
        <!--        企业资讯-->
        <div class="enterpriseInformationBg">
          <p>企业资讯</p>
          <div class="enterpriseInformation">
            <div v-for="(item,index) in newscontentList.slice(0, 4)" :key="index" @click="goNewsDetail(item)">
              <img :src="item.logo"/>
              <div class="enterPriseCenter">
                <p>{{ item.title }}</p>
                <p>{{ item.createTime }}</p>
              </div>
              <p class="conduct" v-if="item.tag&&item.tagColor" >{{item.tag}}</p>
              <p class="enterPriseEnd" v-if="item.tag&&!item.tagColor" >{{item.tag}}</p>
            </div>
          </div>
        </div>
        <!--        行业新闻-->
        <div class="enterpriseInformationBg">
          <p>行业新闻</p>
          <div class="enterpriseInformation">
            <div v-for="(item,index) in zxcontentList.slice(0, 4)" :key="index" @click="goNewsDetail(item)">
              <img :src="item.logo"/>
              <div class="enterPriseCenter">
                <p>{{ item.title }}</p>
                <p>{{ item.createTime }}</p>
              </div>
              <p class="conduct" v-if="item.tag&&item.tagColor" >{{item.tag}}</p>
              <p class="enterPriseEnd" v-if="item.tag&&!item.tagColor" >{{item.tag}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    限时折扣-->
    <div class="BoutiqueBg" v-if="timezheListArr&&timezheListArr.length">
      <div class="boutiqueMr">
        <div>
          <div class="productServe boutiqueCenter">
            <p>限时折扣</p>
            <p>LIMITED-TIME DISCOUNT</p>
          </div>
        </div>
        <div class="bouteiqueMore">
          <p class="more" @click="goCourseList('discount')">更多</p>
          <span>>></span>
        </div>
        <div v-if="timezheListArr&&timezheListArr.length" style="justify-content: space-between">
          <div class="discountContent" v-for="(item,index) in timezheListArr" :key="index" @click="goCourseDetail(item)">
            <img class="limitTime" :src="item.img"/>
            <div>
              <p>{{ item.name }}</p>
<!--              <p v-if="item.teacherUser">{{ item.teacherUser.name }}</p>-->
              <p>
                <span>¥{{item.skus[0].price}}</span>
                <span>学习人数:{{item.studyPeopleNumber}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    拼团优惠-->
<!--    <div class="BoutiqueBg">-->
<!--      <div class="boutiqueMr">-->
<!--        <div>-->
<!--          <div class="productServe boutiqueCenter">-->
<!--            <p>拼团优惠</p>-->
<!--            <p>SPELLING A GROUP DISCOUNT</p>-->
<!--          </div>-->
<!--          <p class="more" @click="goCourseList">更多></p>-->
<!--        </div>-->
<!--        <div class="pintuanB">-->
<!--          <div class="discountContent" v-for="i in 5" :key="i" @click="goCourseDetail(item)">-->
<!--            <img src="images/ceshiImg1.png"/>-->
<!--            <div>-->
<!--              <p>手机摄影技巧高手教学1</p>-->
<!--              <p>琳琳老师</p>-->
<!--              <p>-->
<!--                <span>¥29.9</span>-->
<!--                <span>仅剩20</span>-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <!--    名师公开课-->
    <div class="">
      <div class="boutiqueMr">
        <div>
          <div class="productServe boutiqueCenter">
            <p>名师公开课</p>
            <p>FAMOUS TEACHERS OPEN CLASS</p>
          </div>
        </div>
        <div class="bouteiqueMore">
          <p class="more" @click="goCourseList('famousTeacher')">更多</p>
          <span>>></span>
        </div>
        <div>
          <div class="famousTeacherFlex" v-for="(item,i) in famousCourserListArr.slice(0,6)" :key="i" @click="goCourseDetail(item)">
            <img :src="item.logo" class="famousTeacherFlexImg"/>
            <div class="famousTeacherConten">
              <p>{{item.name}}</p>
              <p>
                <span v-if="item.teacherUser">{{item.teacherUser.name}}</span>
                <span></span>
              </p>
              <p>时长:{{item.min}}</p>
              <p>
                <span v-if="item.teacherUser">{{item.teacherUser.position}}</span>
<!--                <span>讲师</span>-->
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    直播课堂-->
<!--    <div class="BoutiqueBg">-->
<!--      <div class="boutiqueMr">-->
<!--        <div>-->
<!--          <div class="productServe boutiqueCenter">-->
<!--            <p>直播课堂</p>-->
<!--            <p>LIVE CLASSROOM </p>-->
<!--          </div>-->
<!--          <p class="more">更多></p>-->
<!--        </div>-->
<!--        <div class="pintuanB">-->
<!--          <div class="streamingFlex" v-for="i in 3" :key="i">-->
<!--            <img src="images/ceshi.jpg"/>-->
<!--            <div class="streamingContent">-->
<!--              <p>跨境电商的基础知识</p>-->
<!--              <p>-->
<!--                <span>明晚7点</span>-->
<!--                <span>丨</span>-->
<!--                <span>预约课程</span>-->
<!--                &lt;!&ndash;                <span>直播中</span>&ndash;&gt;-->
<!--              </p>-->
<!--              <p>-->
<!--                <span>林筱筱  </span>-->
<!--                <span>智捷电商高级讲师</span>-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!--    名师一览-->
    <div class="BoutiqueBg boutiqueTeacherMr">
      <div class="boutiqueMr">
        <div>
          <div class="productServe boutiqueCenter">
            <p>名师一览</p>
            <p>A TEACHER IN </p>
          </div>
        </div>
        <div class="bouteiqueMore">
          <p class="more" @click="goTeacherList()">更多</p>
          <span>>></span>
        </div>
        <div class="celebratedSwiperImg">
<!--          <img src="images/left.png" alt="" @click="moveSwiperLeft"/>-->
          <div >
<!--            <div class="celebratedTeacherBig" :style="{-->
<!--                        width: FamousteacherList.length * 360 + 'px',-->
<!--                        'margin-left': -360 * swiperCont + 'px',-->
<!--                      }">-->
            <div class="celebratedTeacherBig">
              <div class="celebratedTeacherFlex" v-for="(item,index) in FamousteacherList.slice(0,4)" :key="index" @click="goteacherDetail(item)">
                <div style="width: 106px;margin-right: 22px;">
                  <img class="hoverImgIndex" :src="item.logo?item.logo:'images/zanwuAvatar.png'"/>
                </div>
                <div class="celebratedTeacherConten">
                  <p>
                    <span></span>
                    <span >{{ item.name?item.name:item.account }}</span>
                  </p>
                  <p v-if="item.position">{{ item.position?item.position:'' }}</p>
                  <p>{{ item.goodAt}}</p>
                  <p>{{ item.profiles }}</p>
                </div>
              </div>
            </div>
          </div>
<!--          <img src="images/right.png" alt="" @click="moveswiperRight"/>-->
        </div>
      </div>
    </div>
    <!--    合作院校-->
    <div style="background: #464c5a;padding-bottom: 20px">
      <div>
        <div class="productServe cooperationCneter">
          <p style="color: white">合作院校</p>
          <p style="color: white">PARTNER SCHOOLS</p>
        </div>
        <div class="schoolWd" >

          <div :style="{height:'600px',width:'72%'}" ref="myEchart"></div>
          <div style="margin-top: 115px;">
            <img src="images/map.gif"/>
            <!--            <div v-if="shandongShow" >-->
            <!--              <img src="images/shandong.svg"/>-->
            <!--            </div>-->
            <!--            <div v-if="ningxiaShow">-->
            <!--              <img src="images/ningxia.svg"/>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <div class="celebratedSwiperImgmap">
        <img class="swiperLeft" src="images/left.png" alt="" @click="moveSwiperLeft"/>
        <div class="celebratedSwiperhidden">
          <div class="celebratedTeacherBig" :style="{
                        width: schoolList.length * 286 + 'px',
                        'margin-left': -286 * swiperCont + 'px',
                      }">
            <div class="celebratedmapFlex" v-for="(item,index) in schoolList" :key="index" >
              <img :src="item.img"/>
            </div>
          </div>
        </div>
        <img class="swiperLeft" src="images/right.png" alt="" @click="moveswiperRight"/>
      </div>
    </div>
    <!--    底部-->
    <div class="">
<!--      <div class="companyFlex">-->
<!--        <p>地址：山东省济南市历城区将军路荣盛时代国际C座724</p>-->
<!--        <p>邮编：250100</p>-->
<!--        <p>电话：0531—88259780</p>-->
<!--        <p>电子邮箱：zjds151210@163.com</p>-->
<!--      </div>-->
    <Footer></Footer>
    </div>
    <el-tooltip placement="top" content="回到顶部">
      <back-to-top
          :custom-style="myBackToTopStyle"
          :visibility-height="300"
          :back-position="0"
          transition-name="fade"
      />
    </el-tooltip>
    <el-dialog
        :visible.sync="videoWhindow"
        :show-close="true"
        :append-to-body="true"
        :destroy-on-close="true">
      <div class="resourceImgas" v-if="videoWhindow">
        <vue-aliplayer-v2   ref="VueAliplayerV2" :options="videoOptions" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BackToTop from "../../node_modules/element-ui/packages/backtop/index";
import {
  bannerList,
  newsList,
  courseList,
  teacherList,
  homeVideoInfo,
  QQLoginApi,
  bindThirdParty,
  updateStudyDay
} from '@/api/index';
import VueAliplayerV2 from 'vue-aliplayer-v2';
import Footer from './footer.vue'
import * as echarts from "echarts";
import '../utils/china.js'
export default {
  components: {
    BackToTop,
    VueAliplayerV2,
    Footer
  },
  data() {
    return {
      myBackToTopStyle: {
        right: "50px",
        bottom: "50px",
        width: "40px",
        height: "40px",
        borderRadius: "4px",
        lineHeight: "45px", // 请保持与高度一致以垂直居中
        background: "#e7eaf1", // 按钮的背景颜色
      },
      serveClassify: [
        {id: 1, img: 'images/zhuanye.png', name: '专业(群)建设'},
        {id: 2, img: 'images/serve.png', name: '精品课程资源&平台研发'},
        {id: 3, img: 'images/study.png', name: '产教融合实训基地建设'},
        {id: 4, img: 'images/shixun.png', name: '教学服务支持'},
        {id: 5, img: 'images/occupation.png', name: '人才测评与就创业服务'},
      ],
      serveType: 0,
      enterpriseInfoArr: [
        {id: 1, img: 'images/ceshiImg1.png', title: '关于举办“首届全国跨境电子商务教育大会”的通知', time: '2021-9-6', tag: '举办中'},
        {id: 2, img: 'images/ceshiImg1.png', title: '关于举办“首届全国跨境电子商务教育大会”的通知', time: '2021-9-6', tag: '举办中'},
        {id: 3, img: 'images/ceshiImg1.png', title: '关于举办“首届全国跨境电子商务教育大会”的通知', time: '2021-9-6', tag: '举办中'},
        {id: 4, img: 'images/ceshiImg1.png', title: '关于举办“首届全国跨境电子商务教育大会”的通知', time: '2021-9-6', tag: '举办中'},
      ],
      aboutIndexArr:[
        {id:1,name:'公司介绍'},
        {id:2,name:'荣誉资质'},
        {id:3,name:'加入我们'},
        {id:4,name:'联系我们'},
      ],
      swiperCont: 0,
      bannerList: [],
      bannerData:{
        fileCode: 'WEBHOME'
      },
      newscontentList:[],
      zxcontentList:[],
      newTime:'',
      courserListArr:[],
      famousCourserListArr:[],
      FamousteacherList:[],
      schoolList:[
        {id:1,img:'images/swiper1.jpg'},
        {id:2,img:'images/swiper2.jpg'},
        {id:3,img:'images/swiper3.jpg'},
        {id:4,img:'images/swiper4.jpg'},
        {id:5,img:'images/swiper5.jpg'},
      ],
      timezheListArr:[],
      newsEndTime:"",
      nowTime:"",
      videoOptions: {
      },
      videoWhindow:false,
      filePath:'',
      chart: null,
      shandongShow:false,
      ningxiaShow:false
    }
  },
  methods: {
    //地图
    chinaConfigure() {
      let that=this
      let myChart = echarts.init(this.$refs.myEchart); //这里是为了获得容器所在位置
      myChart.on('mouseover', (params)=> {
        if(params.name=='宁夏'){
          that.ningxiaShow=true;
          that.shandongShow=false
        }else if(params.name=='山东'){
          that.ningxiaShow=false;
          that.shandongShow=true
        }else{
          that.ningxiaShow=false;
          that.shandongShow=false
        }
      });
      window.onresize = myChart.resize;
      myChart.setOption({ // 进行相关配置
        // backgroundColor: "",
        tooltip: {}, // 鼠标移到图里面的浮动提示框
        dataRange: {
          show: false,
          min: 0,
          max: 1000,
          text: ['High', 'Low'],
          realtime: true,
          calculable: true,
          color: ['orangered', 'yellow', 'lightskyblue']
        },
        geo: { // 这个是重点配置区
          map: 'china', // 表示中国地图
          roam: false,
          label: {
            normal: {
              show: true, // 是否显示对应地名
              textStyle: {
                color: 'white'
              }
            }
          },
          itemStyle: {
            normal: {
              borderColor: 'white',
              areaColor: '#50535c'
            },
            emphasis: {
              areaColor: null,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        },
        series: [{
          type: 'scatter',
          coordinateSystem: 'geo' // 对应上方配置
        },
          {
            name: '合作院校', // 浮动框的标题
            type: 'map',
            geoIndex: 0,
            data: [ {
              "name": "山东",
              "value": 14
            },{
              "name": "宁夏",
              "value": 5
            }]
          }
        ]
      })
    },
    serveHover(index) {
      this.serveType = index;
    },
    changeOpenVideo(){
      this.gethomeVideo();
      this.videoWhindow=true
    },
    // 名师一览轮播向左
    moveSwiperLeft() {
      if (this.swiperCont != 0) {
        this.swiperCont--;
      }
    },
    // 名师一览轮播向右
    moveswiperRight() {
      if (this.swiperCont < this.schoolList.length - 4) {
        this.swiperCont++;
      }
    },
    //  去课程列表页面
    goCourseList(val) {
      let data={
        data:val,
      }
      let data1={
        data:val,
        teacherMore:'teacherMore'
      }
      let routeUrl = this.$router.resolve({
        path: "/courseList",
        query:val=='famousTeacher'?data1:data
      });
      window.open(routeUrl.href);
    },

    //  去课程详情
    goCourseDetail(item) {
      item.collectListId=this.collectListId
      sessionStorage.setItem('courseDetail',JSON.stringify(item) )
      // let routeUrl = this.$router.resolve({
      //   path: "/courseDetail",
      // });
      // window.open(routeUrl.href);
      this.$router.push({path: '/courseDetail'})
    },
    //  去新闻资讯列表页面
    gonewsList() {
      this.$router.push('/newsList')
    },
    //  去新闻详情
    goNewsDetail(detail){
      let routeUrl = this.$router.resolve({
        path: "/newsDetail",//新页面地址
      });
      sessionStorage.setItem('newsDetail',JSON.stringify(detail))
      window.open(routeUrl.href);
    },
    //  去教师列表
    goTeacherList() {
      let routeUrl = this.$router.resolve({
        path: "/teacherList",
      });
      window.open(routeUrl.href);
    },
    //  获取banner
    getBanner() {
      bannerList(this.bannerData).then(res => {
        this.bannerList = res.data.records
      })
    },
    //获取首页视频接口
    gethomeVideo(){
      let data={
        fileCode:'QCVI'
      };
      bannerList(data).then(res => {
        if(res.data.records&&res.data.records.length){
          let id=res.data.records[0].id
          this.filePath=res.data.records[0].filePath
          let quety={
            id:id
          };
          homeVideoInfo(quety).then(res=>{
            let newPlayAuth = {
              playauth:res.data.playAuth,
              vid:res.data.videoMeta.videoId,
              // autoplay:false,
            }
            this.videoOptions = newPlayAuth;

          })
        }
      })
    },
    //  获取资讯列表
    getInformationList(){
      let data={
        page:1,
        size:4,
        newsType:1
      }
      newsList(data).then(res => {
        this.newTime=new Date()
        res.data.records.forEach(item=>{
          this.newscontentList.push(item)
          if(item.endTime&&item.endTime!=null){
            let newsEndTime=new Date(item.endTime);
            let newNewsEndTime=newsEndTime.getTime();
            item.endTime=newNewsEndTime;
            let nowTime=new Date().getTime();
            if(item.endTime>=nowTime){
              item.tag='举办中';
              item.tagColor=true
            }else{
              item.tag='已结束';
              item.tagColor=false
            }
          }
        })
      })
    },
  //  获取新闻列表
    getNewsList(){
      let data={
        page:1,
        size:4,
        newsType:2
      }
      newsList(data).then(res => {
        this.newTime=new Date()
        res.data.records.forEach(item=>{
          this.zxcontentList.push(item)
          if(item.endTime&&item.endTime!=null){
            let newsEndTime=new Date(item.endTime);
            let newNewsEndTime=newsEndTime.getTime();
            item.endTime=newNewsEndTime;
            let nowTime=new Date().getTime();
            if(item.endTime>=nowTime){
              item.tag='举办中';
              item.tagColor=true
            }else{
              item.tag='已结束';
              item.tagColor=false
            }
          }
        })
      })
    },
  //  获取精品体系课
    getBoutiqueCourse(){
      let data={
        labels:'精品体系课',
      }
      courseList(data).then(res => {
        this.courserListArr=res.data.records
      })
    },
    //  获取名师公开课
    getfamousTeacherCourse(){
      let data={
        labels:'名师公开课'
      }
      courseList(data).then(res => {
        this.famousCourserListArr=res.data.records;
        res.data.records.forEach((item,index)=>{
          if(item.resourceDuration){
            let min=parseInt(item.resourceDuration/60)
            this.$set(this.famousCourserListArr[index],  "min", min);
          }
        })
      })
    },
  //  获取限时折扣
    getTimeZhe(){
      let data={
        activityType:'discount'
      }
      courseList(data).then(res => {
        this.timezheListArr=res.data.records;
      })
    },
  //  获取名师一览
    getFamousTeacherList(){
      let data={
        page:1,
        size:100
      }
      teacherList(data).then(res => {
        if(res.data.records&&res.data.records.length){
          this.FamousteacherList=res.data.records;
        }

      })
    },
    //  去教师详情
    goteacherDetail(item){
      sessionStorage.setItem('teacherDetail',JSON.stringify(item))
      let routeUrl = this.$router.resolve({
        path: "/teacherDetail",
        query:{id:item.id}
      });
      window.open(routeUrl.href);
    },

  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.getBanner();
    this.getNewsList();
    this.getInformationList()
    this.getBoutiqueCourse();
    this.getfamousTeacherCourse();
    this.getFamousTeacherList();
    this.getTimeZhe();
    this.gethomeVideo();
    this.chinaConfigure();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  beforeCreate(){
    let getQueryString = (name, data)=>{
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = data.substr(3).match(reg);
      if (r != null) return unescape(r[2]); return null;
    }
    let accessToken = getQueryString('access_token', window.location.hash)
    let callback = (res)=> {
      sessionStorage.setItem('unionid', res.unionid);
      let bindQQUiconID = sessionStorage.getItem('bindQQUiconID')
      let unionid = res.unionid;

      let getQQUserInfo= "/getQqUserInfo";
      this.axios
          .get(getQQUserInfo, {
            params: {
              access_token: accessToken,
              oauth_consumer_key:'101968322',
              openid:res.openid,
            },
          })
          .then((resInfo) => {
            if (!bindQQUiconID) {
              let data={
                qqId:unionid,
                terminalType:'WEB',
                gender:resInfo.data.gender=='女'?'female':'male',
                nickName:resInfo.data.nickname,
                headPortraitUrl:resInfo.data.figureurl_2
              }
              QQLoginApi(data).then(res=>{
                if (res.code == 0) {
                  this.$message({
                    message: "登录成功",
                    type: "success",
                  });
                  updateStudyDay().then(res=>{

                  })
                  this.$cookie.set("userName", res.data.nickName);
                  this.$store.commit("setUserName", res.data.nickName);
                  let userInfo =res.data
                  userInfo.name=res.data.nickName;
                  userInfo.logo=res.data.headPortraitUrl;
                  let newUserInfo = JSON.stringify(userInfo);
                  localStorage.setItem("userInfo", newUserInfo);
                  this.$cookie.set("userToken", res.data.loginToken);
                  window.location.href = "http://exiaozhi.sdzjds.com"
                }
              })
            }else{
              let boundData={
                qqId:unionid,
                terminalType:'WEB',
                gender:resInfo.gender,
                nickName:resInfo.nickname,
                headPortraitUrl:resInfo.figureurl_2
              };
              bindThirdParty(boundData).then(res=>{
                if (res.code == 0){
                  this.$message({
                    message: "绑定成功",
                    type: "success",
                  });
                  let userInfo =res.data
                  userInfo.logo=res.data.headPortraitUrl;
                  let newUserInfo = JSON.stringify(userInfo)
                  localStorage.setItem("userInfo", newUserInfo);
                  this.$cookie.set("userToken", res.data.loginToken);
                  window.location.href = "http://exiaozhi.sdzjds.com"
                }else{
                  this.$message.error(res.msg)
                }
                sessionStorage.removeItem("bindQQUiconID");
              })
            }
          });
    };
    if(accessToken){
      // let getQqUserInfoUrl = 'https://graph.qq.com/oauth2.0/me';
      //"/qqUser"
      let getQqUserInfoUrl = "/qqUser";
      this.axios
          .get(getQqUserInfoUrl, {
            params: {
              access_token: accessToken,
              unionid: 1,
            },
          })
          .then((res) => {
            if(res.data){
              eval(res.data);
            }
          });
    }else if(window.location.search){
      let url=window.location.search;
      var dz_url = url.split('#')[0]; //获取#/之前的字符串
      var cs = dz_url.split('?')[1];//获取?之后的参数字符串
      var cs_arr = cs.split('&');//参数字符串分割为数组
      var cs={};
      for(var i=0;i<cs_arr.length;i++){//遍历数组，拿到json对象
        cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
      }
      let state=cs.state;
      sessionStorage.setItem("code", cs.code);
      this.$router.replace("/wxlogin");
    }
  }
}
</script>
<style scoped>
@import "../../public/css/index.css";
</style>
