import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import header from "./components/header"
import ElementUI from 'element-ui';       //引入elementui
import 'element-ui/lib/theme-chalk/index.css'; //引入elementui样式
import axios from "axios"
import request from './utils/request'
import Cookies from 'js-cookie'
import util from "./utils/util"
import './assets/app.css'
import './assets/font/iconfont.css'
Vue.prototype.$util = util;
Vue.prototype.$request = request;
Vue.prototype.axios=axios;
Vue.prototype.$cookie = Cookies;
Vue.use(ElementUI);   //将element挂载到Vue
Vue.prototype.$other = new Vue();
Vue.config.productionTip = false
Vue.component("my-header", header);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
