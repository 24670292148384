import request from '@/utils/request'
import qs from  'qs'

import {collectionCourseIdList} from './user'

//首页banner
export function bannerList(query) {
    return request({
        url: '/homePage/list',
        method: 'get',
        params: query
    })
}
//新闻列表
export function newsList(query) {
    return request({
        url: '/platformNews/list',
        method: 'get',
        params: query
    })
}
//教师登录
export function teacherLoginUrl(data) {
    return request({
        url: '/login/teacher',
        method: 'post',
        data
    })
}
//名师列表
export function teacherList(query) {
    return request({
        url: '/teacher/greatList',
        method: 'get',
        params: query
    })
}
//教师信息列表
export function schoolTeacherList(query) {
    return request({
        url: '/teacher/list',
        method: 'get',
        params: query
    })
}
//课程分类
export function courseClassify(query) {
    return request({
        url: '/course/courseClassifyList',
        method: 'get',
        params: query
    })
}
//课程难易度
export function courseDifficulty(query) {
    return request({
        url: '/dictionary/dictionaryItemList',
        method: 'get',
        params: query
    })
}
//课程列表
export function courseList(query) {
    return request({
        url: '/course/list',
        method: 'get',
        params: query
    })
}
//班级列表
export function classList(query) {
    return request({
        url: '/schoolClass/queryTeacherClassList',
        method: 'get',
        params: query
    })
}
//年级名称列表
export function gradeNameList(query) {
    return request({
        url: '/schoolClass/classGradeList',
        method: 'get',
        params: query
    })
}
//班级名称列表
export function classNameList(query) {
    return request({
        url: '/schoolClass/classNameList',
        method: 'get',
        params: query
    })
}
//创建学校班级
export function createClassList(data) {
    return request({
        url: '/schoolClass/create',
        method: 'POST',
        data
    })
}
//班级绑定课程与授课老师
export function boundCourseTeacher(data) {
    return request({
        url: '/schoolClass/bindCourseTeacher',
        method: 'POST',
        data
    })
}
//根据班级id查询班级信息
export function getClassInfo(query) {
    return request({
        url: '/schoolClass/queryById',
        method: 'get',
        params: query
    })
}
//班级课程老师绑定信息列表
export function getClasscourseInfo(query) {
    return request({
        url: '/schoolClass/courseTeacherList',
        method: 'get',
        params: query
    })
}
//班级考勤
export function classClocking(query) {
    return request({
        url: '/schoolClass/attendanceList',
        method: 'get',
        params: query
    })
}
//根据课程id查询课程资源
export function getResourceListApi(query) {
    return request({
        url: '/course/resourceList',
        method: 'get',
        params: query
    })
}
//播放凭证
export function playVoucher(query) {
    return request({
        url: '/course/resourcePlayInfo',
        method: 'get',
        params: query
    })
}
//课程资源下的习题作业
export function operationTopic(query) {
    return request({
        url: '/course/exercisesList',
        method: 'get',
        params: query
    })
}
//查询日志
export function getRizhi(query) {
    return request({
        url: '/learnerDiaries/list',
        method: 'get',
        params: query
    })
}
//修改班级信息
export function amendClass(data) {
    return request({
        url: '/schoolClass/update',
        method: 'POST',
        data
    })
}
//删除班级
export function deleteClass(data) {
    return request({
        url: '/schoolClass/deleted',
        method: 'POST',
        data
    })
}
//班级解绑课程与授课老师
export function unbindClassCourse(data) {
    return request({
        url: '/schoolClass/unbindCourseTeacher',
        method: 'POST',
        data
    })
}
//教师正在授课或归档班级课程信息
export function pigeonholeCourse(query) {
    return request({
        url: '/schoolClass/teachingFileClassCourse',
        method: 'GET',
        params: query
    })
}
//教师管理-启用或禁用教师账号
export function invokeAccount(data) {
    return request({
        url: '/teacher/disableOrEnable',
        method: 'POST',
        data
    })
}
//重置密码
export function resetPassWord(data) {
    return request({
        url: '/teacher/resetPass',
        method: 'POST',
        data
    })
}
//学生列表
export function selectStudentList(query) {
    return request({
        url: '/school/studentList',
        method: 'get',
        params: query
    })
}
//学生个人课程列表
export function belongCourseList(query) {
    return request({
        url: '/course/studentList',
        method: 'get',
        params: query
    })
}
//移除学生
export function removeStudent(data) {
    return request({
        url: '/schoolClass/removeStudent',
        method: 'POST',
        data
    })
}
//修改教师信息
export function amendTeacherApi(data) {
    return request({
        url: '/teacher/edit',
        method: 'POST',
        data
    })
}
//学校课程信息列表
// export function schoolCourseList(query) {
//     return request({
//         url: '/school/courseList',
//         method: 'get',
//         params: query
//     })
// }
//查询学校信息
export function schoolSetInfo(query) {
    return request({
        url: '/school/oneById',
        method: 'get',
        params: query
    })
}
//查询试卷模板列表
export function examinationList(query) {
    return request({
        url: '/examPapers/templateList',
        method: 'get',
        params: query
    })
}
//修改教师信息
export function amendTeacherInfo(data) {
    return request({
        url: '/teacher/edit',
        method: 'POST',
        data
    })
}
//修改教师密码
export function amendTeacherPassword(data) {
    return request({
        url: '/teacher/updatePassword',
        method: 'POST',
        data
    })
}
//获取习题作业列表下的音视频图片
export function audioImg(query) {
    return request({
        url: '/baseFile/queryInfo',
        method: 'post',
        data:query
    })
}
//新建作业
export function addTask(query) {
    return request({
        url: '/homeWork/createClassHomeWork',
        method: 'post',
        data:query
    })
}
//登录教师班级作业列表
export function teacherClassTask(query) {
    return request({
        url: '/homeWork/classWorkList',
        method: 'GET',
        params:query
    })
}
//作业学生列表
export function workStudentList(query) {
    return request({
        url: '/homeWork/classWorkStudentList',
        method: 'GET',
        params:query
    })
}
//教师查询学生作业详情
export function studentWorkDetail(query) {
    return request({
        url: '/homeWork/classHomeWorkItemList',
        method: 'GET',
        params:query
    })
}
//教师批改学生作业
export function teacherCheckWork(query) {
    return request({
        url: '/homeWork/teacherCorrectHomeWork',
        method: 'POST',
        data:query
    })
}
//教师创建习题
export function teacherCreateWork(query) {
    return request({
        url: '/course/createCourseExercises',
        method: 'POST',
        data:query
    })
}
//单个课程分类详情,返回分类概览，教师列表
export function courseClassFyDetail(query) {
    return request({
        url: '/course/courseClassifyDetails',
        method: 'GET',
        params:query
    })
}
//创建试卷模板
export function createExamination(query) {
    return request({
        url: '/examPapers/templateSaveEdit',
        method: 'POST',
        data:query
    })
}
//删除试卷
export function deleteExamination(query) {
    return request({
        url: '/examPapers/templateDeleted',
        method: 'POST',
        data:query
    })
}
//创建试卷模板详情
export function createExaminationDetailAPI(query) {
    return request({
        url: '/examPapers/templateItemSaveEdit',
        method: 'POST',
        data:query
    })
}
//教师查询试卷模板详情列表
export function selectTemplateDetailList(query) {
    return request({
        url: '/examPapers/templateItemList',
        method: 'GET',
        params:query
    })
}
//查询试卷详情根据习题类型聚合3.8
export function itemAggByExercisesType(query) {
    return request({
        url: '/examPapers/itemAggByExercisesType',
        method: 'GET',
        params:query
    })
}
//删除试卷详情
export function deleteExamitionDetail(query) {
    return request({
        url: '/examPapers/templateItemDeleted',
        method: 'POST',
        data:query
    })
}
//随机生成习题
export function randomGenerateExamition(query) {
    return request({
        url: '/course/randomExercisesList',
        method: 'GET',
        params:query
    })
}
//发布考试
export function publishExamition(query) {
    return request({
        url: '/examPapersTestCenter/create',
        method: 'POST',
        data:query
    })
}
//考场列表
export function organizationExamitionList(query) {
    return request({
        url: '/examPapersTestCenter/list',
        method: 'GET',
        params:query
    })
}
//取消考场
export function cancelExamination(query) {
    return request({
        url: '/examPapersTestCenter/cancel',
        method: 'POST',
        data:query
    })
}
//考场试卷统计分析信息
export function examinationStatistics(query) {
    return request({
        url: '/examPapersTestCenter/statisticsPapersTestCenter',
        method: 'GET',
        params:query
    })
}
//考场试卷成绩学生列表
export function examinationPerformanceList(query) {
    return request({
        url: '/examPapersTestCenter/testCenterStudentList',
        method: 'GET',
        params:query
    })
}
//分配给老师批阅的考场列表
export function teacherCheckExaminationList(query) {
    return request({
        url: '/examPapersTestCenter/dispositionTeacherMarkingTestCenterList',
        method: 'GET',
        params:query
    })
}
//分配给老师批阅试卷的考场学生列表
export function teacherCheckExaminationStdList(query) {
    return request({
        url: '/examPapersTestCenter/dispositionTeacherMarkingTestCenterStudentList',
        method: 'GET',
        params:query
    })
}
//教师查询学生测试习题列表
export function teacherCheckExaminationDetail(query) {
    return request({
        url: '/examPapersTestCenter/teacherTestCenterStudentExercisesList',
        method: 'GET',
        params:query
    })
}
//查询教师账号管理下操作记录
export function teacherManagementRecord(query) {
    return request({
        url: '/frontLog/list',
        method: 'GET',
        params:query
    })
}
//教师修改学生信息
export function teacherAmendStdInfo(query) {
    return request({
        url: '/user/teacherEditUser',
        method: 'POST',
        data:query
    })
}
//教室查询学生近期考试列表
export function teacherSelectStdExamList(query) {
    return request({
        url: '/examPapersTestCenter/teacherQueryStudentExamPapersList',
        method: 'GET',
        params:query
    })
}
//统计学生所有考试的分数数据
export function statisticsStudentexamScore(query) {
    return request({
        url: '/examPapersTestCenter/statisticsStudentTestCenterScore',
        method: 'GET',
        params:query
    })
}
//统计学生所有考试的习题星级数据
export function statisticsStudentexamStar(query) {
    return request({
        url: '/examPapersTestCenter/statisticsStudentExercisesStar',
        method: 'GET',
        params:query
    })
}
//教师批改学生试卷
export function teacherCheckStdExercises(query) {
    return request({
        url: '/examPapersTestCenter/teacherMarkingTestCenterAnswer',
        method: 'POST',
        data:query
    })
}
//删除教师习题
export function deleteTeacherExercises(query) {
    return request({
        url: '/course/deletedCourseExercisesByIds',
        method: 'POST',
        data:query
    })
}
//修改试卷模板详情下的分数
export function amendExDetailScore(query) {
    return request({
        url: '/examPapers/editTemplateItemTypeScore',
        method: 'POST',
        data:query
    })
}
//查询教师上传的资源列表
export function inquireTeacheruploadList(query) {
    return request({
        url: '/baseFile/teacherResourceList',
        method: 'GET',
        params:query
    })
}
//添加登录教师备课章节资源
export function addTeacherResource(query) {
    return request({
        url: '/teacher/createTeacherPerparationResource',
        method: 'POST',
        data:query
    })
}
//查询教师备课章节资源列表
export function selectAddTeacherResourceList(query) {
    return request({
        url: '/teacher/teacherPerparationResourceList',
        method: 'GET',
        params:query
    })
}
//删除教师备课章节资源列表
export function deleteAddTeacherResourceList(query) {
    return request({
        url: '/teacher/deletedTeacherPerparationResource',
        method: 'POST',
        data:query
    })
}
//查询登录教师备课章节信息列表
export function selectLoginTeacherResourceList(query) {
    return request({
        url: '/teacher/teacherPerparationCourseItem',
        method: 'GET',
        params:query
    })
}
//添加学生阿里云音视频资源
export function addStdResourceAliYun(query) {
    return request({
        url: '/baseFile/addStudentResourceAliYun',
        method: 'POST',
        data:query
    })
}
//添加教师阿里云音视频资源
export function addTeacherResourceAliYun(query) {
    return request({
        url: '/baseFile/addTeacherResourceAliYun',
        method: 'POST',
        data:query
    })
}
// 删除教师上传的资源
export function deleteTeacherResourceAliYun(query) {
    return request({
        url: '/baseFile/deletedTeacherResource',
        method: 'POST',
        data:query
    })
}
//统计学校习题难度数据
export function statisticsSchoolstart(query) {
    return request({
        url: '/statistics/schoolExercisesStar',
        method: 'GET',
        params:query
    })
}
//教师上传基础文件
export function teacherUpLoad(query) {
    return request({
        url: '/baseFile/upload',
        method: 'POST',
        data:query,
        headers: {'Content-Type': 'multipart/form-data'}
    })
}
//统计学校课程分类数据
export function schoolStatisticsCourseClassfy(query) {
    return request({
        url: '/statistics/schoolCourseClassify',
        method: 'GET',
        params:query
    })
}
//统计学校课程数据
export function schoolStatisticsCourseNubmber(query) {
    return request({
        url: '/statistics/schoolCourse',
        method: 'GET',
        params:query
    })
}
//创建或修改教师自建课程
export function createAmendTeacherCourse(query) {
    return request({
        url: '/teacherCourse/createOrEditCourse',
        method: 'POST',
        data:query
    })
}
//首页视频播放信息
export function homeVideoInfo(query) {
    return request({
        url: '/aliYunFile/homePageVideoPlayInfo',
        method: 'GET',
        params:query
    })
}
//删除教师自建课程
export function deleteTeacherCourse(query) {
    return request({
        url: '/teacherCourse/deletedCourse',
        method: 'POST',
        data:query
    })
}
//创建或修改教师自建课程的章节
export function cresteTeacherCoursePreper(query) {
    return request({
        url: '/teacherCourse/createOrEditCourseItem',
        method: 'POST',
        data:query
    })
}
//删除教师自建课程章节信息
export function deleteTeacherCoursePreper(query) {
    return request({
        url: '/teacherCourse/deletedCourseItem',
        method: 'POST',
        data:query
    })
}
//教师更新班级授课进度或自有课程进度
export function teacherUpdateProgress(query) {
    return request({
        url: '/schoolClass/updateClassCourseProgress',
        method: 'POST',
        data:query
    })
}
//19.3统计学校习题数据
export function statisticsSchoolexercises(query) {
    return request({
        url: '/statistics/schoolExercises',
        method: 'GET',
        params:query
    })
}
//19.5统计学校资源数据
export function statisticsSchoolresource(query) {
    return request({
        url: '/statistics/schoolResource',
        method: 'GET',
        params:query
    })
}
//教师批量上传习题
export function teacherBatchupload(query) {
    return request({
        url: '/course/createCourseExercisesByExcel',
        method: 'POST',
        data:query
    })
}
//学生用户注册
export function studentUserReg(query) {
    return request({
        url: '/user/baseRegister',
        method: 'POST',
        data:query
    })
}
//学生微信登录
export function wxLoginApi(query) {
    return request({
        url: '/login/loginWx',
        method: 'POST',
        data:query
    })
}
//教师微信登录
export function teacherWxLoginApi(query) {
    return request({
        url: '/login/loginWxTeacher',
        method: 'POST',
        data:query
    })
}
//qq登录
export function QQLoginApi(query) {
    return request({
        url: '/login/loginQq',
        method: 'POST',
        data:query
    })
}
//发送手机验证码
export function sendPhoneCode(query) {
    return request({
        url: '/mobSms/sendVerify',
        method: 'GET',
        params:query
    })
}
//学生用户验证码登录
export function studentCodeLogin(query) {
    return request({
        url: '/login/telNumberAndVerifyCode',
        method: 'POST',
        data:query
    })
}
//学生用户手机号密码登录
export function studentPasswordLogin(query) {
    return request({
        url: '/login/telNumberAndPassword',
        method: 'POST',
        data:query
    })
}
//用户收藏课程
export function studentcollectCource(query) {
    return request({
        url: '/user/collection/create',
        method: 'POST',
        data:query
    })
}
//用户取消收藏课程
export function cancleCollectCource(query) {
    return request({
        url: '/user/collection/deleted',
        method: 'POST',
        data:query
    })
}
//查询用户收藏课程id集合
export function selectCollectCourceId(query) {
    return request({
        url: '/user/collection/list',
        method: 'GET',
        params:query
    })
}
//绑定用户第三方信息
export function bindThirdParty(query) {
    return request({
        url: '/user/bindThirdParty',
        method: 'POST',
        data:query
    })
}
//绑定教师微信
export function bindTeacherWx(query) {
    return request({
        url: '/teacher/bindThirdPartyTeacher',
        method: 'POST',
        data:query
    })
}
//解绑教师微信
export function unBindTeacherWx(query) {
    return request({
        url: '/teacher/unBindThirdPartyTeacher',
        method: 'POST',
        data:query
    })
}
//登录学生修改密码
export function studentUpPassword(query) {
    return request({
        url: '/user/updatePassword',
        method: 'POST',
        data:query
    })
}
//更新登录用户学习天数
export function updateStudyDay(query) {
    return request({
        url: '/user/studyDays/update',
        method: 'GET',
        params:query
    })
}

//获取用户收藏信息列表，并保存缓存
//必须保证用户信息已存入到缓存中，并且key为userInfo
//该方法返回用户所有的收藏课程id集合,每次调用都会刷新缓存数据
export function queryAndCacheUserCollectionCourseList(){
    let resultData = [];
    let loginUserData = JSON.parse(localStorage.getItem("userInfo"));
    if(loginUserData==null||typeof(loginUserData)=="undefined"){
        return resultData;
    }
    return new Promise(function(resolve, reject){
        collectionCourseIdList().then(res=>{
            if(res.code=="0"){
                localStorage.setItem("userCollectionCourseIdList",JSON.stringify(res.data));
                resolve(res.data)
            }else{
                resolve([])
            }
        })
    });
}




//用户修改或绑定手机号
export function amendUserPhone(query) {
    return request({
        url: '/user/bindTelNumber',
        method: 'POST',
        data:query
    })
}
//用户注销账号
export function logoutAccount(query) {
    return request({
        url: '/user/cancellation',
        method: 'GET',
        params:query
    })
}
//用户解绑第三方
export function unbundleAccount(query) {
    return request({
        url: '/user/unBindThirdParty',
        method: 'POST',
        data:query
    })
}
//登录用户绑定手机号并设置新密码
export function userBoundPhonePsd(query) {
    return request({
        url: '/user/bindTelNumberAndSetPassword',
        method: 'POST',
        data:query
    })
}
//教师设置课程章节为已备课状态
export function perparationResourceIsPerparation(query) {
    return request({
        url: '/teacher/perparationResourceIsPerparation',
        method: 'GET',
        params:query
    })
}
//查询登录教师自有课程进度列表
export function courseProgressList(query) {
    return request({
        url: '/teacher/courseProgressList',
        method: 'GET',
        params:query
    })
}
//创建课程订单
export function createCourseOrVipOrder(query) {
    return request({
        url: '/order/createCourseOrVipOrder',
        method: 'POST',
        data:query
    })
}
//微信或支付宝支付
export function payMoney(query) {
    return request({
        url: '/pay/apply',
        method: 'POST',
        data:query
    })
}
//支付宝支付
export function payAliMoney(query) {
    return request({
        url: '/pay/apply/ali',
        method: 'POST',
        data:query
    })
}
//分页查询课程类别评论列表
export function commentList(query) {
    return request({
        url: '/courseClassifyComment/commentList',
        method: 'GET',
        params:query
    })
}
//创建课程类别评论
export function createComment(query) {
    return request({
        url: '/courseClassifyComment/createComment',
        method: 'POST',
        data:query
    })
}
//课程类别评论回复列表
export function commentReplyList(query) {
    return request({
        url: '/courseClassifyComment/commentReplyList',
        method: 'GET',
        params:query
    })
}
//创建课程类别评论回复
export function createCommentReply(query) {
    return request({
        url: '/courseClassifyComment/createCommentReply',
        method: 'POST',
        data:query
    })
}
//用户订单列表
export function orderList(query) {
    return request({
        url: '/order/list',
        method: 'GET',
        params:query
    })
}
//查询用户是否购买课程
export function checkUserPayCourse(query) {
    return request({
        url: '/user/checkUserPayCourse',
        method: 'GET',
        params:query
    })
}
//实战训练类型列表
export function drillList(query) {
    return request({
        url: '/trainingtask/type/list',
        method: 'GET',
        params:query
    })
}
//实战训练类型介绍
export function drillType(query) {
    return request({
        url: '/trainingtask/type/contentText',
        method: 'GET',
        params:query
    })
}
//实战训练模块列表
export function drillModuleList(query) {
    return request({
        url: '/trainingtask/module/list',
        method: 'GET',
        params:query
    })
}
//实战训练任务列表
export function drillTaskList(query) {
    return request({
        url: '/trainingtask/content/list',
        method: 'GET',
        params:query
    })
}
//实战训练任务目录列表
export function drillTaskCatalogList(query) {
    return request({
        url: '/trainingtask/contentCatalog/list',
        method: 'GET',
        params:query
    })
}
//实战训练任务目录内容详情
export function drillTaskCatalogDetail(query) {
    return request({
        url: '/trainingtask/contentCatalog/contentText',
        method: 'GET',
        params:query
    })
}
//用户提交任务
export function userSubmitTask(query) {
    return request({
        url: '/trainingtask/content/saveStudentResult',
        method: 'POST',
        data:query
    })
}
//实战训练任务指定学生结果
export function userTaskDetail(query) {
    return request({
        url: '/trainingtask/content/studentResult',
        method: 'GET',
        params:query
    })
}
//添加实战训练教师对学生任务评论
export function saveStudentResultEvaluate(query) {
    return request({
        url: '/trainingtask/saveStudentResultEvaluate',
        method: 'POST',
        data:query
    })
}
//查询实战训练教师对学生任务评论列表
export function studentResultEvaluateList(query) {
    return request({
        url: '/trainingtask/studentResultEvaluateList',
        method: 'GET',
        params:query
    })
}
//添加实战训练学生对教师回复
export function saveStudentResultEvaluateReply(query) {
    return request({
        url: '/trainingtask/saveStudentResultEvaluateReply',
        method: 'POST',
        data:query
    })
}
//查询实战训练学生对教师回复列表
export function studentResultEvaluateReplyList(query) {
    return request({
        url: '/trainingtask/studentResultEvaluateReplyList',
        method: 'GET',
        params:query
    })
}
//实战训练学生解锁任务目录
export function saveStudentCatalogProgress(query) {
    return request({
        url: '/trainingtask/saveStudentCatalogProgress',
        method: 'POST',
        data:query
    })
}
//职教高考备考视频列表
export function preparingVideoList(query) {
    return request({
        url: '/collegeEntrance/preparingVideoList',
        method: 'GET',
        params:query
    })
}
//职教高考播放备考课程视频
export function aliYunFileVideoPlayAuth(query) {
    return request({
        url: '/aliYunFile/aliYunFileVideoPlayAuth',
        method: 'GET',
        params:query
    })
}
//职教高考专业列表
export function majorList(query) {
    return request({
        url: '/collegeEntrance/majorList',
        method: 'GET',
        params:query
    })
}
//职教高考学科列表
export function subjectList(query) {
    return request({
        url: '/collegeEntrance/subjectList',
        method: 'GET',
        params:query
    })
}
//职教高考教材列表
export function textbooksList(query) {
    return request({
        url: '/collegeEntrance/textbooksList',
        method: 'GET',
        params:query
    })
}
//职教高考教材章节列表
export function textbooksChapterList(query) {
    return request({
        url: '/collegeEntrance/textbooksChapterList',
        method: 'GET',
        params:query
    })
}
//职教高考教材章节习题列表
export function textbooksChapterExercisesList(query) {
    return request({
        url: '/collegeEntrance/textbooksChapterExercisesList',
        method: 'GET',
        params:query
    })
}
//随机查询职教高考教材章节习题列表
export function randomChapterExercisesList(query) {
    return request({
        url: '/collegeEntrance/randomSelectionTextbooksChapterExercisesList',
        method: 'GET',
        params:query
    })
}
//查询职教高考平台试卷模板列表
export function collegeEntranceTemplateList(query) {
    return request({
        url: '/examPapers/collegeEntranceTemplateList',
        method: 'GET',
        params:query
    })
}
//学生查询测试试卷列表
export function studentExamPapersList(query) {
    return request({
        url: '/examPapersTestCenter/studentExamPapersList',
        method: 'GET',
        params:query
    })
}
//学生使用该接口查询所有测试试卷习题列表(错题本)
export function studentErroExercisesList(query) {
    return request({
        url: '/examPapersTestCenter/studentExercisesList',
        method: 'GET',
        params:query
    })
}
//职教高考平台试卷模板习题列表
export function collegeEntranceTemplateItemList(query) {
    return request({
        url: '/examPapers/collegeEntranceTemplateItemList',
        method: 'GET',
        params:query
    })
}
//学生随机组卷职教高考
export function studentTemplateSave(query) {
    return request({
        url: '/examPapers/studentTemplateSave',
        method: 'POST',
        data:query
    })
}
//用户追加资源播放记录
export function appendPlayLog(query) {
    return request({
        url: '/user/playResourceLog/appendPlayLog',
        method: 'POST',
        data:query
    })
}
//教师一键组卷
export function randomPaper(query) {
    return request({
        url: '/examPapers/randomGenerationExamPapersItem',
        method: 'POST',
        data:query
    })
}
//查询一键组卷题库数量
export function randomPaperNumber(query) {
    return request({
        url: '/collegeEntrance/queryExercisesTotalByCollegeEntranceSubjectId',
        method: 'GET',
        params:query
    })
}
//职场提升课程章和视频列表
export function resourceListByItem(query) {
    return request({
        url: '/course/resourceListByItem',
        method: 'GET',
        params:query
    })
}
//获取职场提升相关推荐课程列表
export function getCorrelationCourse(query) {
    return request({
        url: '/course/correlatedList',
        method: 'GET',
        params:query
    })
}
//大数据岗位数据分析
export function getJobDataNubmer(query) {
    return request({
        url: '/bigDataJob/selectBigDataJobTypeAndJobPercentage',
        method: 'GET',
        params:query
    })
}
//大数据热门行业
export function getHotIndustry(query) {
    return request({
        url: '/bigDataJob/selectBigDataJobTypeAndJobSalary',
        method: 'GET',
        params:query
    })
}
//大数据经验薪资
export function getExperience(query) {
    return request({
        url: '/bigDataJob/selectBigDataJobExperienceAndJobSalary',
        method: 'GET',
        params:query
    })
}
//大数据能力素质
export function getCapacityquality(query) {
    return request({
        url: '/bigDataJob/selectBigDataJobCapacityQuality',
        method: 'GET',
        params:query
    })
}
//大数据学历要求
export function getEducation(query) {
    return request({
        url: '/bigDataJob/selectBigDataJobSchool',
        method: 'GET',
        params:query
    })
}
//大数据地图
export function selectBigDataAll(query) {
    return request({
        url: '/bigDataJob/selectBigDataAll',
        method: 'GET',
        params:query
    })
}
//微信订单支付确认是否支付成功
export function findOrderStatus(query) {
    return request({
        url: '/pay/findOrderStatus',
        method: 'GET',
        params:query
    })
}
//查询职场提升系列课程总价
export function findOrderTotal(query) {
    return request({
        url: '/order/findAllPrice',
        method: 'GET',
        params:query
    })
}
//确认订单系列一套课
export function afrimOrder(query) {
    return request({
        url: '/order/createMultiCourseOrVipOrder',
        method: 'POST',
        data:query
    })
}
//用户学习记录增加或修改学习时间
export function addRecentStudy(query) {
    return request({
        url: '/user/addRecentStudy',
        method: 'POST',
        data:query
    })
}
//职场提升留言板
export function addLeaveWords(query) {
    return request({
        url: '/user/addLeaveWords',
        method: 'POST',
        data:query
    })
}
//全系价格查询接口  增加课程大类
export function findAllPrice(query) {
    return request({
        url: '/order/findAllPrice',
        method: 'GET',
        params:query
    })
}
//加入购物车
export function addTrolley(query) {
    return request({
        url: '/trolley/addTrolley',
        method: 'POST',
        data:query
    })
}
//获取购物车信息
export function findTrolley(query) {
    return request({
        url: '/trolley/findTrolley',
        method: 'GET',
        params:query
    })
}
//删除购物车
export function delTrolley(query) {
    return request({
        url: '/trolley/delTrolley',
        method: 'POST',
        data:query
    })
}
// 购物车创建订单
export function createTrolleyOrder(query) {
    return request({
        url: '/order/createTrolleyOrder',
        method: 'POST',
        data:query
    })
}
//获取购物车信息
export function addVisit(query) {
    return request({
        url: '/user/addVisit',
        method: 'GET',
        params:query
    })
}
//课程详情用户获取章节列表
export function findItemList(query) {
    return request({
        url: '/course/findItemList',
        method: 'GET',
        params:query
    })
}
//获取职教高考新闻内容
export function findContent(query) {
    return request({
        url: '/platformNews/findContent',
        method: 'GET',
        params:query
    })
}
//是否已加入购物车
export function checkUserAddTrolley(query) {
    return request({
        url: '/trolley/checkUserAddTrolley',
        method: 'GET',
        params:query
    })
}
//小智job新闻列表
export function jobNewsList(query) {
    return request({
        url: '/journalism/findJournalism',
        method: 'POST',
        data:query
    })
}
//个人用户未存在job表 则创建无需传参 每次job页面直接调用
export  function findLoginType(query){
    return request({
        url: '/jobUser/findLoginType',
        method: 'GET',
        params:query
    })
}
//查询职位信息及公司信息,可添加搜索条件 recommend:推荐/职位搜索, newest:最新, hot:热招, school:校园
export function findPosition(query) {
    return request({
        url: '/position/findPosition',
        method: 'POST',
        data:query
    })
}
//热门企业
export function findCompany(query) {
    return request({
        url: '/position/findCompany',
        method: 'POST',
        data:query
    })
}
//行业领域列表
export  function industryList(query){
    return request({
        url: '/journalism/industryList',
        method: 'GET',
        params:query
    })
}
//个人简历信息
export function findUserByuId(query) {
    return request({
        url: '/jobUser/findUserByuId',
        method: 'POST',
        data:query
    })
}
//修改个人简历
export function updateUserById(query) {
    return request({
        url: '/jobUser/updateUserById',
        method: 'POST',
        data:query
    })
}
//职位列表
export  function postList(query){
    return request({
        url: '/journalism/postList',
        method: 'GET',
        params:query
    })
}
//修改简历(期望职位,.....)
export function updateUserPost(query) {
    return request({
        url: '/jobUser/updateUserPost',
        method: 'POST',
        data:query
    })
}
//根据企业用户id添加绑定一条公司数据
export function addCompanyInfo(query) {
    return request({
        url: '/company/insertCompanyByJobUserId',
        method: 'GET',
        params:query
    })
}
//根据企业用户id查看绑定一条公司数据
export function selectCompanyInfo(query) {
    return request({
        url: '/company/selectCompanyByJobUserId',
        method: 'GET',
        params:query
    })
}
//根据公司id修改一条公司数据
export function updateCompanyInfo(query) {
    return request({
        url: '/company/updateCompanyById',
        method: 'GET',
        params:query
    })
}
//查询职位推荐牛人
export function findPostUser(query) {
    return request({
        url: '/jobUser/findPostUser',
        method: 'GET',
        params:query
    })
}
//公司发布职位
export function publishCompanypost(query) {
    return request({
        url: '/companyJob/insertCompanyJobByCompanyId',
        method: 'GET',
        params:query
    })
}
//获取企业id
export function findCompanyId(query) {
    return request({
        url: '/jobUser/findCompanyId',
        method: 'GET',
        params:query
    })
}
//根据公司id查询所有发布的工作
export function selectCompanyJobByCompanyId(query) {
    return request({
        url: '/companyJob/selectCompanyJobByCompanyId',
        method: 'GET',
        params:query
    })
}
//根据主键id修改一条公司发布的工作数据
export function updateCompanyJobById(query) {
    return request({
        url: '/companyJob/updateCompanyJobById',
        method: 'GET',
        params:query
    })
}
//根据主键id删除一条公司发布的工作数据
export function deleteCompanyJobById(query) {
    return request({
        url: '/companyJob/deleteCompanyJobById',
        method: 'GET',
        params:query
    })
}
//获取聊天好友列表
export function findfriendList(query) {
    return request({
        url: '/chatMsg/find',
        method: 'GET',
        params:query
    })
}
//上传附件
export function uploadAccessory(query) {
    return request({
        url: '/jobUser/other/create',
        method: 'POST',
        data:query
    })
}
//删除个人附件简历
export function delectAdjunct(query) {
    return request({
        url: '/jobUser/other/delect',
        method: 'POST',
        data:query
    })
}
//小智job,城市大数据
export function cityInformation(query) {
    return request({
        url: '/journalism/city',
        method: 'GET',
        params:query
    })
}
//删除所有个人信息, id 必传 post:期望职位,experience:工作经历,project:项目经验,school:教育经历
export function delectEducation(query) {
    return request({
        url: '/jobUser/delUserPost',
        method: 'POST',
        data:query
    })
}
//根据id修改公司信息 id必传
export function updateCompany(query) {
    return request({
        url: '/company/update',
        method: 'POST',
        data:query
    })
}
//查询招聘数据
export function inviteData(query) {
    return request({
        url: '/companyJob/find',
        method: 'GET',
        params:query
    })
}
//浏览牛人或者牛人浏览职位
export function browseTalents(query) {
    return request({
        url: '/companyJob/addBrowse',
        method: 'GET',
        params:query
    })
}
//查询企业人用户信息
export function findCompanyUserById(query) {
    return request({
        url: '/jobUser/findCompanyUserById',
        method: 'GET',
        params:query
    })
}
