import request from '@/utils/request'

const userApi = {
    passwordLogin:'/login/telNumberAndPassword',
    userCourseList:'/course/userList',
    joinCLass:'/user/joinClass',
    schoolClassWorkList:'/homeWork/studentList',
    schoolClassWorkDetails:"/homeWork/studentHomeWorkItemList",
    submitHomeWork:"/homeWork/studentSubmitHomeWork",
    examPapersList:"/examPapersTestCenter/studentExamPapersList",
    examPapersExercisesList:"/examPapersTestCenter/teacherTestCenterStudentExercisesList",
    examPapersTestCenterStatistics:"/examPapersTestCenter/studentTestCenterStatistics",
    submitTestCenterAnswer:"/examPapersTestCenter/studentSubmitTestCenterAnswer",
    myCourseList:'/course/userList',
    myStudyNotesList:"/user/note/list",
    createOrEditStudyNotes:"/user/note/createOrEdit",
    deletedStudyNotes:"/user/note/deleted",
    editBaseInfo:'/user/editUser',
    uploadAvatar:'/baseFile/upload',
    getStudyDays:"/user/studyDays/query",
    playResourceTime:'/user/playResourceLog/playTotalTime',
    collectionCourseIds:'/user/collection/list',
    studyRecord:'user/findRecentStudy'
}

/**
 * 学生账号密码登录
 * @param {*} params
 * @returns
 */
export function loginByPassword(params){
    return request({
        url: userApi.passwordLogin,
        method: 'POST',
        data:params
    })
}

/**
 * 查询登录用户课程列表
 * @param {*} params
 * @returns
 */
export function userCourseList(params){
    return request({
        url: userApi.userCourseList,
        method: 'get',
        params:params
    })
}

/**
 * 加入班级
 * @param {*} params
 * @returns
 */
export function joinClass(params){
    return request({
        url: userApi.joinCLass,
        method: 'POST',
        data:params
    })
}

/**
 * 查询作业列表
 * @param {*} params
 * @returns
 */
export function querySchoolClassWorkList(params){
    return request({
        url: userApi.schoolClassWorkList,
        method: 'get',
        params:params
    })
}

/**
 * 查询作业详情
 * @param {*} params
 * @returns
 */
export function querySchoolClassWorkItemList(params){
    return request({
        url: userApi.schoolClassWorkDetails,
        method: 'get',
        params:params
    })
}

/**
 * 提交家庭作业
 * @param {*} params
 * @returns
 */
export function submitSchoolClassWork(params){
    return request({
        url: userApi.submitHomeWork,
        method: 'post',
        data:params
    })
}

/**
 * 提交测试试卷
 * @param {*} params
 * @returns
 */
 export function submitSchoolClassTestCenterAnswer(params){
    return request({
        url: userApi.submitTestCenterAnswer,
        method: 'post',
        data:params
    })
}

/**
 * 查询试卷列表
 * @param {*} params
 */
 export function findExamPapersList(params){
    return request({
        url: userApi.examPapersList,
        method: 'get',
        params:params
    })
}

/**
 * 查询试卷习题列表
 * @param {*} params
 * @returns
 */
export function findExamPapersExercisesList(params){
    return request({
        url: userApi.examPapersExercisesList,
        method: 'get',
        params:params
    })
}

/**
 * 查询试卷习题摘要信息
 * @param {*} params
 * @returns
 */
export function findExamPapersExercisesStatistics(params){
    return request({
        url: userApi.examPapersTestCenterStatistics,
        method: 'get',
        params:params
    })
}

/**
 * 查询拥有的用户课程列表
 * @param {*} params
 * @returns
 */
export function selfCourseList(params){
    return request({
        url: userApi.myCourseList,
        method: 'get',
        params:params
    })
}

/**
 * 查询学习笔记列表
 * @param {*} params
 * @returns
 */
export function selfStudyNotesList(params){
    return request({
        url: userApi.myStudyNotesList,
        method: 'get',
        params:params
    })
}

/**
 * 新增或编辑学习笔记
 * @param {*} params
 * @returns
 */
export function selfCreateOrEditStudyNotes(params){
    return request({
        url: userApi.createOrEditStudyNotes,
        method: 'post',
        data:params
    })
}

/**
 * 删除学习笔记
 * @param {*} params
 * @returns
 */
export function selfDeletedStudyNotes(params){
    return request({
        url: userApi.deletedStudyNotes,
        method: 'post',
        data:params
    })
}

/**
 * 修改用户基本信息
 * @param {*} params
 * @returns
 */
export function editUserBaseInfo(params){
    return request({
        url: userApi.editBaseInfo,
        method: 'post',
        data:params
    })
}

/**
 * 用户上传头像
 * @param {*} params
 * @returns
 */
export function uploadUserAvatar(params){
    return request({
        url: userApi.uploadAvatar,
        method: 'post',
        data:params
    })
}

/**
 * 查询登录用户学习天数
 * @returns
 */
export function findStudyDays(){
    return request({
        url: userApi.getStudyDays,
        method: 'get'
    })
}

/**
 * 查询用户播放资源时长
 * @returns
 */
export function findPlayResourceTime(){
    //playResourceTime
    return request({
        url: userApi.playResourceTime,
        method: 'get'
    })
}

export function collectionCourseIdList(){
    return request({
        url: userApi.collectionCourseIds,
        method: 'get'
    })
}

//查询用户学习记录
export function studyRecord(params){
    return request({
        url: userApi.studyRecord,
        method: 'post',
        data:params
    })
}
