<!--<script src="../../../exiaozhi-user-ui/vue.config.js"></script>-->
<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/"></router-link>
    </div> -->
    <my-header></my-header>
    <router-view/>
  </div>
</template>
<script>
  export default{
    data(){
      return{
        gap_time: 0,
        beforeUnload_time: 0,
      }
    },
    // methods:{
    //   // 关闭窗口之前执行
    //   beforeunloadHandler() {
    //     this.beforeUnload_time = new Date().getTime();
    //   },
    //   unloadHandler() {
    //     this.gap_time = new Date().getTime() - this.beforeUnload_time;
    //     //判断是窗口关闭还是刷新 毫秒数判断 网上大部分写的是5
    //     if (this.gap_time <= 10) {
    //       this.$cookie.remove("userToken");
    //       this.$cookie.remove("userName");
    //       this.$cookie.remove("studyFrontToken");
    //       sessionStorage.clear();
    //       localStorage.clear(); // 退出登录接口
    //     } else {
    //       // console.log(document.domain);
    //       // return confirm("确定要离开本系统么？");
    //     }
    //   },
    //
    // },
    // unmounted() {//vue可换为destroyed()生命周期，不过这个也可以用
    //   // 移除监听
    //   window.removeEventListener("beforeunload", () => this.beforeunloadHandler());
    //   window.removeEventListener("unload", () => this.unloadHandler());
    // },
    // mounted() {
    //   // 监听浏览器关闭
    //   window.addEventListener("beforeunload", () => this.beforeunloadHandler());
    //   window.addEventListener("unload", () => this.unloadHandler());
    // },

  }
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  padding: 0;
  margin: 0;
}
ul>li{
  list-style-type: none;
}
</style>
